import Settings from "./views/Settings";

const Routes = [
    {
        path: "/settings",
        name: "admin-settings",
        component: Settings,
        meta: {
            title: 'Setari aplicatie',
        }
    },
]

export default Routes;