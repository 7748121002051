<template>
  <div>
  <v-container v-if="pages">
    <h2 class="mb-5">Pagini</h2>
    <v-card>
      <v-card-title>
        Lista
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cauta..." single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :headers="headers" :search="search" :custom-filter="customFilterRomoveDiacritics" no-data-text="Nu sunt inregistrari" :items="pages" mobile-breakpoint="800" class="elevation-0">
        <template #item.full_name="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="editPage(item)" icon class="mx-0" color="primary">
                  <v-icon>mdi-clipboard-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editeaza</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :loading="item.id === deletePreloader" v-bind="attrs" v-on="on" @click="deletePage(item)" icon class="mx-0" color="pink">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Sterge</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-btn color="green lighten-2" @click="addPage" dark class="mt-3 mb-3">
      Pagina noua
      <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
    </v-btn>
  </v-container>

  <v-progress-linear v-else indeterminate color="indigo"></v-progress-linear>

  <v-dialog v-model="dialog" persistent scrollable fullscreen transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar elevation="1" style="z-index: 2" flat color="white">
          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="text-h5 py-4" v-text="page.id ? 'Editeaza pagina' : 'Pagina noua'"></span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="!valid" :loading="savePreloader" color="primary" @click="save">{{ page.id ? 'SALVEAZA' : 'ADAUGA' }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="pt-3">
          <v-form v-model="valid">
            <v-text-field v-model="page.name" :rules="required" label="Nume" required></v-text-field>
            <v-text-field v-if="page.id" v-model="page.slug" :rules="required" label="Slug" required></v-text-field>
            <div class="pt-4">
              <editor v-model="page.body" v-if="page" />
            </div>
          </v-form>
        </v-card-text>

      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { PagesActions, PagesMutations, PagesGetters } from "@admin/modules/pages/shared/pages.store";
import Editor from '@core/views/TinyMCE-Editor';

export default {
  name: "Pages",
  components: {
    Editor
  },
  data() {
    return {
      dialog: false,
      savePreloader: false,
      deletePreloader: false,
      valid: false,
      search: '',

      required: [
        v => !!v || 'Camp obligatoriu'
      ],

      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nume', value: 'name' },
        { text: 'Slug', value: 'slug' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },
  async mounted() {
    await PagesActions.init();
  },
  computed: {
    pages: PagesGetters.getPages,
    page: PagesGetters.getPage
  },
  methods: {
    editPage(page) {
      this.dialog = true;
      PagesMutations.setPage({ ...page });
    },
    addPage() {
      this.dialog = true;
      PagesMutations.setPage({
        name: '',
        slug: '',
        body: ''
      });
    },
    async save() {
      this.savePreloader = true;
      const data = {
        name: this.page.name,
        slug: this.page.slug,
        body: this.page.body
      }
      if (this.page.id) {
        await PagesActions.updatePage(this.page.id, data);
      } else {
        await PagesActions.addPage(data);
      }
      this.savePreloader = false;
      this.closeModal();
    },
    async deletePage(page) {
      this.deletePreloader = page.id;
      if (confirm("Sigur doriți să ștergeți această pagina?")) {
        await PagesActions.deletePage(page.id);
      }
      this.deletePreloader = false;
    },
    closeModal() {
      this.dialog = false;
      PagesMutations.setPage({});
    },
    customFilterRomoveDiacritics(value, search) {
      if (search && value) {
        if (typeof value === 'string') {
          return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.toLowerCase());
        } else {
          return value == search;
        }
      }
    },
  }
}
</script>