<template>
  <v-container v-if="certifications">
    <h2 class="mb-5">Diplome</h2>

    <v-card>
      <v-card-title>
        Lista
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cauta..." single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :search="search" :custom-filter="customFilterRomoveDiacritics" no-data-text="Niciun utilizator activ" :items="filteredCertifications" mobile-breakpoint="800" class="elevation-0">

        <template v-slot:header.userName="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="userName ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field clearable hide-details="true" v-model="userName" class="pa-4" type="text" label="Cauta..."></v-text-field>
            </div>
          </v-menu>
        </template>

        <template v-slot:header.userEmail="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="userEmail ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field clearable hide-details="true" v-model="userEmail" class="pa-4" type="text" label="Cauta..."></v-text-field>
            </div>
          </v-menu>
        </template>

        <template #item.download="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" target="_blank" v-on="on" :href="APP_SERVER + '/api/admin/certification/' + item.id + '/download/'" icon class="mx-0" color="success">
                <v-icon>mdi-file-certificate-outline</v-icon>
              </v-btn>
            </template>
            <span>Descarca diploma</span>
          </v-tooltip>
        </template>

        <template #item.serieNumber="{ item }">{{ item.serial }} {{ item.number }}</template>
        <template #item.percentage="{ item }">{{ item.percentage }}%</template>

        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="showUserDetails(item.receivedBy)" icon class="mx-0">
                  <v-icon>mdi-account-question-outline</v-icon>
                </v-btn>
              </template>
              <span>Detalii utilizator</span>
            </v-tooltip>
          </div>
        </template>

      </v-data-table>

    </v-card>

    <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="600">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Detalii utilizator</v-toolbar>
          <v-card-text>
            <div class="mt-8" v-if="userDetails">
              <p>Nume: <strong>{{userDetails.first_name}} {{userDetails.last_name}}</strong></p>
              <p>Email: <strong>{{userDetails.email}}</strong></p>
              <p v-if="userDetails.cuim">CUIM: <strong>{{userDetails.cuim}}</strong></p>
              <p v-if="userDetails.phone">Telefon: <strong>{{userDetails.phone}}</strong></p>
              <p v-if="userDetails.judet">Judet: <strong>{{userDetails.judet}}</strong></p>
              <p v-if="userDetails.specialitate">Specialitate 1: <strong>{{userDetails.specialitate}}</strong>, grad <strong>{{userDetails.gradProfesional}}</strong></p>
              <p v-if="userDetails.specialitate2">Specialitate 2: <strong>{{userDetails.specialitate2}}</strong>, grad <strong>{{userDetails.gradProfesional2}}</strong></p>
              <p>Creat la: <strong>{{userDetails.created_at}}</strong></p>
              <div class="mt-3">
                <v-chip small class="mr-2" v-for="role in userDetails.roles" :key="role">{{ role }}</v-chip>
              </div>
            </div>

            <div class="text-center mt-5" v-else>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeDialog">Inchide</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

  </v-container>
  <v-progress-linear v-else indeterminate color="indigo"></v-progress-linear>
</template>

<script>
import { CertificationsActions, CertificationsGetters } from "@admin/modules/certifications/shared/certification.store";
import { UsersActions, UsersGetters, UsersMutations } from "@admin/modules/users/shared/users.store";

export default {
  name: "Certifications",

  data() {
    return {
      APP_SERVER: process.env.VUE_APP_SERVER,

      dialog: false,
      userName: '',
      userEmail: '',
      search: '',

      headers: [
        { text: '#', value: 'download', align: 'center', sortable: false },
        { text: 'Id', value: 'id' },
        { text: 'Curs', value: 'courseName' },
        { text: 'User', value: 'userName' },
        { text: 'Email', value: 'userEmail' },
        { text: 'Serie / Numar', value: 'serieNumber' },
        { text: 'Procentaj', value: 'percentage', align: 'center' },
        { text: 'Primit la', value: 'receivedAt' },
        { text: 'User', value: 'actions', sortable: false },
      ],
    }
  },
  async mounted() {
    await CertificationsActions.init();
  },
  computed: {
    certifications: CertificationsGetters.getCertifications,
    userDetails: UsersGetters.getUser,

    filteredCertifications() {
      let conditions = [];

      if (this.userName) {
        conditions.push(this.filterUserName);
      }
      if (this.userEmail) {
        conditions.push(this.filterUserEmail);
      }

      if (conditions.length > 0) {
        return this.certifications.filter((certification) => {
          return conditions.every((condition) => {
            return condition(certification);
          })
        })
      }
      return this.certifications;
    }
  },
  methods: {
    async showUserDetails(id) {
      this.dialog = true;
      await UsersActions.fetchOneUser(id);
    },
    closeDialog() {
      this.dialog = false;
      UsersMutations.setUser(null);
    },

    //
    filterUserName(item) {
      return item.userName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.userName.toLowerCase());
    },
    filterUserEmail(item) {
      return item.userEmail.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.userEmail.toLowerCase());
    },
    customFilterRomoveDiacritics(value, search) {
      if (search && value) {
        if (typeof value === 'string') {
          return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.toLowerCase());
        } else {
          return value == search;
        }
      }
    },
  }
}
</script>