import Organizatori from "./views/Organizatori";

const Routes = [
    {
        path: "/organizatori",
        name: "admin-organizatori",
        component: Organizatori,
        meta: {
            title: 'Organizatori',
        }
    },
]

export default Routes;