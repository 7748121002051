import Vue from "vue";
import { usersApi } from "@admin/modules/users/api";

const initialState = {
    stateStatus: false,

    loadingUsers: false,
    users: [],
    user: null
}

const state = Vue.observable({ ...initialState });
const roles = ['ROLE_USER', 'ROLE_ADMIN']


const Getters = {
    getLoadingUsers: () => state.loadingUsers,
    getUsers: () => state.users,
    getUser: () => state.user,
    getRoles: () => roles
}

const Mutations = {
    setLoadingUsers: (payload) => {
        state.loadingUsers = payload;
    },
    setUsers: (payload) => {
        state.users = payload;
    },
    setUser: (payload) => {
        state.user = payload;
    },
    addUser: (payload) => {
        state.users.push(payload);
    },
    updateUser: (id, payload) => {
        state.users = state.users.map((user) => { return (user.id===id) ? payload : user })
    },
    updateUserStatus: (id) => {
        state.users = state.users.map((user) => {
            if(user.id===id) {
                user.is_active = !user.is_active
            }
            return user
        })
    }
}

const Actions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await Actions.fetchUsers();
        }
        state.stateStatus = true;
    },
    fetchUsers: async () => {
        Mutations.setLoadingUsers(true);
        const courses = await usersApi.getAll();
        Mutations.setLoadingUsers(false);
        Mutations.setUsers(courses.data);
    },
    fetchOneUser: async (id) => {
        const user = await usersApi.getOne(id);
        Mutations.setUser(user.data);
    },
    addUser: async (data) => {
        const response = await usersApi.addOne(data);
        if (response.status) {
            Mutations.addUser(response.data);
        }
        return response.status
    },
    updateUser: async (id, data) => {
        const response = await usersApi.updateOne(id, data);
        if (response.status) {
            Mutations.updateUser(id, response.data);
        }
    }
}

export const UsersGetters = Getters;
export const UsersMutations = Mutations;
export const UsersActions = Actions;
