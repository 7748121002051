import Article from "./views/Article";
import Articles from "./views/Articles";
import CourseArticles from "./views/CourseArticles";

const Routes = [
    {
        path: "/article/:id",
        name: "admin-article",
        component: Article,
        meta: {
            title: 'Articol',
        }
    },
    {
        path: "/articles",
        name: "admin-articles",
        component: Articles,
        meta: {
            title: 'Articole',
        }
    },
    {
        path: "/course-articles/:id",
        name: "admin-course-articles",
        component: CourseArticles
    },
]

export default Routes;