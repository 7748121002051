import Vue from "vue";
import { courseApi } from "@admin/modules/courses/api";

const initialState = {
    stateStatus: false,

    loadingCourses: false,
    courses: [],
}

const state = Vue.observable({ ...initialState });

const Getters = {
    getLoadingCourses: () => state.loadingCourses,
    getCourses: () => state.courses,
}

const Mutations = {
    setLoadingCourses: (payload) => {
        state.loadingCourses = payload;
    },
    setCourses: (payload) => {
        state.courses = payload;
    },
    addCourse: (payload) => {
        state.courses.push(payload);
    },
    deleteCourse: (id) => {
        state.courses = state.courses.filter(item => item.id != id);
    },
}

const Actions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await Actions.fetchCourses();
        }
        state.stateStatus = true;
    },
    fetchCourses: async () => {
        Mutations.setLoadingCourses(true);
        const courses = await courseApi.getAll();
        Mutations.setLoadingCourses(false);
        Mutations.setCourses(courses.data);
    },
    addOneCourse: async (data) => {
        const response = await courseApi.addOne(data);
        if (response.status) {
            Mutations.addCourse(response.data);
        }
    },
    deteleOneCourse: async (id) => {
        const response = await courseApi.deleteOne(id);
        if (response.status) {
            Mutations.deleteCourse(id);
        }
        return response
    },
}

export const CoursesGetters = Getters;
export const CoursesMutations = Mutations;
export const CoursesActions = Actions;
