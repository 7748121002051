import Dashboard from "./modules/dashboard";
import coursesRoutes from "./modules/courses/courses.routes";
import articlesRoutes from "./modules/articles/articles.routes";
import usersRoutes from "./modules/users/users.routes";
import organizatoriRoutes from "./modules/organizatori/organizatori.routes"
import coordonatoriRoutes from "./modules/coordonatori/coordonatori.routes"
import specializariRoutes from "./modules/specializari/specializari.routes"
import pagesRoutes from "./modules/pages/pages.routes"
import certificationsRoutes from "./modules/certifications/certification.routes"
import surveysRoutes from "./modules/surveys/surveys.routes"
import settingsRoutes from "./modules/settings/settings.routes"

const Routes = [
    {
        path: "/",
        name: "admin-dashboard",
        component: Dashboard,
    },

    ...coursesRoutes,
    ...articlesRoutes,
    ...usersRoutes,
    ...organizatoriRoutes,
    ...coordonatoriRoutes,
    ...specializariRoutes,
    ...pagesRoutes,
    ...certificationsRoutes,
    ...surveysRoutes,
    ...settingsRoutes,
]

export default Routes;