<template>

  <v-navigation-drawer v-if="isAuthenticated" app permanent :expand-on-hover="expand" height="100%" dark mobile-breakpoint="md" bottom>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{user.first_name}} {{user.last_name}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{user.email}}<br>
            <span class="text-sm-caption">Administrator</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link :to="{ path: '/' }" exact>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Inapoi la site</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link :to="{ name: 'admin-dashboard' }" exact>
        <v-list-item-icon>
          <v-icon>mdi-monitor-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-courses' }">
        <v-list-item-icon>
          <v-icon>mdi-book-open-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cursuri</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-articles' }">
        <v-list-item-icon>
          <v-icon>mdi-page-next-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Articole</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-organizatori' }">
        <v-list-item-icon>
          <v-icon>mdi-account-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Organizatori</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-coordonatori' }">
        <v-list-item-icon>
          <v-icon>mdi-account-star-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Coordonatori</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-specializari' }">
        <v-list-item-icon>
          <v-icon>mdi-school-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Specializari / Grade profesionale</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-certifications' }">
        <v-list-item-icon>
          <v-icon>mdi-certificate-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Diplome</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-pages' }">
        <v-list-item-icon>
          <v-icon>mdi-note-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Pagini</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-users' }">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Utilizatori</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-survey-questions' }">
        <v-list-item-icon>
          <v-icon>mdi-counter</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Intrebari sondaj</v-list-item-title>
      </v-list-item>

      <v-list-item link :to="{ name: 'admin-settings' }">
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Setari aplicatie</v-list-item-title>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>

</template>

<script>
export default {
  props: ["user", "isAuthenticated"],
  data() {
    return {
      expand: true
    }
  },
  computed: {
  },
  created() {},
  mounted() {},
  methods: {
  },
  components: {

  },
}
</script>

<style scoped>

</style>