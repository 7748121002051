<template>
  <div>
    <h1>Cursuri</h1>

    <v-row no-gutters v-if="loading">
      <v-col cols="12" sm="6" xs="12">
        <v-card elevation="1" outlined class="ma-2">
          <v-card-text>
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" xs="12">
        <v-card elevation="1" outlined class="ma-2">
          <v-card-text>
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters v-else>
      <v-col cols="12" sm="6" xs="12" v-for="course in courses" :key="course.id">
        <v-card elevation="1" outlined class="ma-2" @click="goToCourse(course)">
          <v-row no-gutters>
            <v-col cols="12" sm="8" xs="12">
              <v-card-title class="text-h5">{{ course.name }}</v-card-title>
              <v-card-subtitle>{{ course.short_description }}</v-card-subtitle>
              <v-card-text>
                <template v-if="Object.keys(course['certification']).length">
                  <v-btn :to="{ name: 'myAccountComponent', params: { componentName: 'certifications' } }" :color="course['certification']['passCourse'] ? 'success' : 'red'" outlined rounded small>
                    <template v-if="course['certification']['passCourse']"><v-icon class="mr-2" small>mdi-check-circle</v-icon> FELICITARI</template>
                    <template v-else><v-icon class="mr-2" small>mdi-close-circle</v-icon> RESPINS</template>
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn :color="course.startCourseQuizzes ? 'primary' : ''" outlined rounded small>
                    <template v-if="!course.startCourseQuizzes">START</template>
                    <template v-else><v-icon class="mr-2">mdi-play</v-icon> CONTINUA</template>
                  </v-btn>
                </template>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="4" xs="12">
              <v-avatar size="100%" tile>
                <v-img v-if="course.link_image" :src="APP_SERVER + course.link_image" aspect-ratio="1.4"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>
<script>
export default {
  props: ["courses", "courseId", "loading"],

  data() {
    return {
      APP_SERVER: process.env.VUE_APP_SERVER,
    }
  },
  computed: {
    courseIdLocal: {
      get: function() {
        return this.courseId
      },
      set: function(value) {
        this.$emit('changeCourseId', value)
      }
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    goToCourse(course) {
      if (this.courseIdLocal != course.id) {
        this.$router.push({ name: 'course', params: { courseId: course.id }});
        this.courseIdLocal = course.id
      }
    },
  }
}
</script>