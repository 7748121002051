import Vue from "vue";
import { coursesApi } from "@courses/api";

const initialState = {
    loadingCourses: false,
    loadingCourse: false,

    courses: null,
    course: null,
}

const state = Vue.observable({ ...initialState });

const Getters = {
    getLoadingCourses: () => state.loadingCourses,
    getLoadingCourse: () => state.loadingCourse,

    getCourses: () => state.courses,
    getCourse: () => state.course,
}

const Mutations = {
    setLoadingCourses: (payload) => {
        state.loadingCourses = payload;
    },
    setLoadingCourse: (payload) => {
        state.loadingCourse = payload;
    },

    setCourses: (payload) => {
        state.courses = payload;
    },
    setCourse: (payload) => {
        state.course = payload;
    },
}

const Actions = {
    fetchCourses: async () => {
        Mutations.setLoadingCourses(true);
        const courses = await coursesApi.getAllCourses();
        Mutations.setLoadingCourses(false);
        Mutations.setCourses(courses.data);
    },
    fetchCourse: async (id) => {
        Mutations.setLoadingCourse(true);
        if (state.course && state.course.id != id) {
            Mutations.setCourse(null);
        }
        const course = await coursesApi.getOneCourse(id);
        Mutations.setLoadingCourse(false);
        Mutations.setCourse(course.data);
    },

    addUserQiuzAnswersToCouse(courseId, articleId, res) {
        let newCourses = state.courses.map(course => {
            if (courseId == course.id) {
                return {
                    ...course,
                    certification: res.certification,
                    startCourseQuizzes: true,
                }
            } else {
                return course;
            }
        });
        Mutations.setCourses(newCourses);

        let newCourse = {
            ...state.course,
            articles: state.course.articles.map(article => {
                return {
                    ...article,
                    userAnswers: articleId == article.id ? res.data.userAnswers : article.userAnswers,
                }
            })
        }
        Mutations.setCourse(newCourse);
    }
}

export const CoursesGetters = Getters;
export const CoursesMutations = Mutations;
export const CoursesActions = Actions;
