<template>

  <v-container v-if="article">

    <v-btn
        rounded
        small
        color="primary"
        dark
        absolute
        top
        right
        :to="{name: 'admin-course-articles', params: {id: article['course_id']}}"
    >
      Inapoi la articolele cursului
    </v-btn>

    <v-card class="mt-10">
      <v-tabs
          v-model="tab"
          background-color="primary"
          centered
          dark
          icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Articol
          <v-icon>mdi-page-next-outline</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Chestionar
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-tab>

      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item :key="1" :value="'tab-1'" transition="fade-transition">
          <v-card flat>

            <v-container>
              <h2 class="mb-5">{{article['name']}}</h2>
              <h3>
                Articolul apartine cursului: {{ article['course_name'] }}
              </h3>


              <v-alert
                  :value="alertSuccess"
                  outlined
                  type="success"
                  text
                  transition="scale-transition"
              >
                <v-row align="center">
                  <v-col class="grow">
                    Articolul a fost actualizat cu success!
                  </v-col>
                  <v-col class="shrink">
                    <v-btn @click="alertSuccess=false">OK</v-btn>
                  </v-col>
                </v-row>
              </v-alert>

              <v-alert
                  :value="alertFail"
                  outlined
                  type="warning"
                  text
                  transition="scale-transition"
              >
                <v-row align="center">
                  <v-col class="grow">
                    Actualizare esuata!
                  </v-col>
                  <v-col class="shrink">
                    <v-btn @click="alertFail=false">OK</v-btn>
                  </v-col>
                </v-row>
              </v-alert>


              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-container>

                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="article['name']"
                          :rules="[v => !!v || 'Titlul articolului este obligatoriu']"
                          label="Titlu articol"
                          required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-textarea
                          v-model="article['short_description']"
                          label="Descriere pe scurt a articolului"
                          auto-grow
                          rows="2"
                          counter
                          required
                          :rules="[
                          v => !!v || 'Descrierea pe scurt este obligatorie',
                          v => (v && v.length<=256) || 'Maxim 256 caractere'
                      ]"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                </v-container>
              </v-form>


              <v-container>
                <p>Descriere articol:</p>
                <Editor v-model="article['description']"  />
                <div class="mt-5">
                  <v-btn
                      rounded
                      color="red"
                      outlined
                      dark
                      @click="deleteArticolModal"
                      x-large
                      class="float-left"
                  >
                    Sterge articol
                  </v-btn>

                  <v-btn
                      rounded
                      color="green"
                      dark
                      @click="saveModificariArticol()"
                      x-large
                      :loading="loading"
                      class="float-right"
                  >
                    Salveaza modificari
                  </v-btn>

                  <div style="clear: both"></div>
                </div>
              </v-container>
            </v-container>

          </v-card>
        </v-tab-item>



        <v-tab-item :key="2" :value="'tab-2'" transition="fade-transition">
          <v-card flat>

            <ArticleQuizes :articleId="id" />

          </v-card>
        </v-tab-item>

      </v-tabs-items>
    </v-card>


    <v-dialog
        v-model="modalDelete"
        persistent
        max-width="450"
    >
      <v-card>
        <v-card-title class="text-break">
          Sigur doriti stergerea acestui articol? Chestionarul atasat impreuna cu intrebarile si raspunsurile acestuia vor fi sterse!
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="modalDelete=false"
          >
            Anuleaza
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="deleteArticol"
              :loading="loadingDelete"
          >
            Da
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>

  <v-progress-linear v-else
                     indeterminate
                     color="indigo"
  ></v-progress-linear>

</template>


<script>
import { articleApi } from "@admin/modules/articles/api";
import ArticleQuizes from "../components/ArticleQuizes";
import goTo from "vuetify/lib/services/goto";
import Editor from '@core/views/TinyMCE-Editor';

export default {
  props: ["user", "isAuthenticated"],
  components: {
    ArticleQuizes,
    Editor
  },
  data() {
    return {
      'id': this.$route.params.id,

      article: null,

      alertSuccess: false,
      alertFail: false,

      modalDelete: false,

      tab: null,

      valid: false,
      nameRules: [
        v => !!v || 'Titlul articolului este obligatoriu'
      ],

      loading: false,
      loadingDelete: false,
    }
  },
  mounted() {
    this.loadQuizData()
  },
  methods: {
    async loadQuizData() {
      const article = await articleApi.getOne(this.id);
      this.article = article.data;
      //console.log(article)
    },

    async saveModificariArticol() {
      this.loading = true
      if( this.$refs.form.validate() ) {
        const response = await articleApi.updateOne(this.id, this.article);
        if (response.status) {
          this.alertSuccess = true
          this.autoCloseAlert()
          goTo(0)
        } else {
          this.alertFail = true
        }
      }
      this.loading = false
    },

    deleteArticolModal() {
      this.modalDelete = true
    },

    async deleteArticol() {
      this.loadingDelete = true
      const response = await articleApi.deleteOneArticle(this.id);
      if (response.status) {
        await this.$router.push({name: 'admin-course-articles', params: {id: this.article['course_id']}})
      }
      this.loadingDelete = false
    },

    autoCloseAlert() {
      setTimeout(function () {
        this.alertSuccess = false
      }.bind(this), 5000)
    }
  }
}
</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>