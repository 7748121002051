<template>
  <div>
    <h2 class="text-center">EMCB</h2>

    <v-container>
      <div style="margin: 0 auto; max-width: 450px">
        <v-alert :value="alertSuccess" prominent outlined type="success" dense text transition="scale-transition">
          <v-row align="center">
            <v-col class="grow">
              Contul a fost creat cu succes!
            </v-col>
            <v-col class="shrink">
              <v-btn icon @click="alertSuccess=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <v-alert type="error" dense outlined v-for="error in this.errors" :key="error.id">
          {{ error }}
        </v-alert>

        <v-card class="mx-auto mb-5">
          <v-card-title class="text-h6 font-weight-regular justify-space-between">
            <span><v-icon>mdi-login-variant</v-icon> Autentificare</span>
          </v-card-title>
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-card-text>
              <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
              <v-text-field v-model="password" type="password" :rules="requiredRule" label="Password" required @keyup.enter="login"></v-text-field>

              <v-btn text class="float-right" x-small :to="{ name: 'recuperare-parola' }">Ai uitat parola?</v-btn>
              <div style="clear:both;"></div>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-text>
              <v-btn :disabled="!valid" large left color="success" :loading="loading" class="mr-4" @click="login">
                LOGIN
              </v-btn>

              <v-btn color="primary" text large class="float-right" @click="showRegModal()">
                CREARE CONT
              </v-btn>
            </v-card-text>
          </v-form>

        </v-card>

      </div>
    </v-container>
    <RegisterModal ref="child" @alertSucces="alertSuccess=true" />
  </div>
</template>
<script>
import { AuthActions, AuthGetters } from "@auth/shared/auth.store";
import RegisterModal from "../components/RegisterModal";

export default {
  components: {
    RegisterModal
  },
  data() {
    return {
      valid: false,
      loading: false,

      alertSuccess: false,

      email: null,
      password: null,

      errors: [],

      requiredRule: [
        v => !!v || 'Required'
      ],
      emailRules: [
        v => !!v || 'E-mail obligatoriu',
        v => /.+@.+/.test(v) || 'E-mailul trebuie sa fie valid',
      ],
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    async login() {
      this.loading = true;

      await AuthActions.loginUser({
        email: this.email,
        password: this.password
      });

      if (AuthGetters.getErrors()) {
        this.errors = AuthGetters.getErrors();
      } else {
        this.errors = [];
        this.$router.push({ name: 'courses' });
      }
      this.loading = false;
    },

    showRegModal() {
      this.$refs.child.openModal()
    }
  }
}
</script>