<template>
<div>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Creaza un cont</v-toolbar-title>
        </v-toolbar>

        <v-container>
          <v-alert :value="alertFail" outlined type="warning" text transition="scale-transition">
            <v-row align="center">
              <v-col class="grow">
                Exista deja un cont cu acest email!
              </v-col>
              <v-col class="shrink">
                <v-btn @click="alertFail=false">OK</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-form ref="form" v-model="valid" lazy-validation v-if="items.specialitati && items.grade_didactice">
            <v-container>
              <v-row>
                <v-col sm="4" cols="12">
                  <v-text-field v-model="newUser.email" label="Email *"
                      :rules="[
                          v => !!v || 'E-mail obligatoriu',
                          v => /.+@.+/.test(v) || 'E-mailul trebuie sa fie valid',
                          ]"
                      required
                  ></v-text-field>
                </v-col>

                <v-col sm="4" cols="12">
                  <v-text-field v-model="newUser.nume" label="Nume *"
                      :rules="[
                          v => !!v || 'Introduceti numele'
                      ]"
                      required
                  ></v-text-field>
                </v-col>

                <v-col sm="4" cols="12">
                  <v-text-field v-model="newUser.prenume" label="Prenume *"
                      :rules="[
                          v => !!v || 'Introduceti prenumele'
                      ]"
                      required
                  ></v-text-field>
                </v-col>

                <v-col sm="6" cols="12">
                  <v-select v-model="newUser.specialitate_1" :items="items.specialitati" label="Specialitatea 1 *"
                      :rules="[
                          v => !!v || 'Alegeti specialitatea'
                      ]"
                      required
                  ></v-select>
                </v-col>

                <v-col sm="6" cols="12">
                  <v-select v-model="newUser.grad_profesional_1" :items="items.grade_didactice" label="Gradul profesional didactic *"
                      :rules="[
                          v => !!v || 'Alegeti gradul didactic'
                      ]"
                      required
                  ></v-select>
                </v-col>

                <v-col sm="6" cols="12">
                  <v-select clearable v-model="newUser.specialitate_2" :items="items.specialitati" label="Specialitatea 2" :rules="[bothOrNone]"></v-select>
                </v-col>

                <v-col sm="6" cols="12">
                  <v-select clearable v-model="newUser.grad_profesional_2" :items="items.grade_didactice" label="Gradul profesional didactic" :rules="[bothOrNone]"></v-select>
                </v-col>

                <v-col sm="4" cols="12">
                  <v-text-field v-model="newUser.cuim" label="CUIM *"
                      :rules="[
                          v => /^\d+$/.test(v) || 'Doar cifre'
                      ]"
                      required
                  ></v-text-field>
                </v-col>

                <v-col sm="4" cols="12">
                  <v-autocomplete v-model="newUser.judet" label="Judet *" :items="items.judete"
                      :rules="[
                          v => !!v || 'Alegeti judetul'
                      ]"
                      required
                  ></v-autocomplete>
                </v-col>

                <v-col sm="4" cols="12">
                  <v-text-field v-model="newUser.telefon" label="Telefon *"
                      :rules="[
                          v => !!v || 'Introduceti telefonul'
                      ]"
                      required
                  ></v-text-field>
                </v-col>

                <v-col sm="6" cols="12">
                  <v-text-field v-model="newUser.pass1" label="Parola *" type="password"
                      :rules="[
                          v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special',
                          v => (v || '').length >= 8 || 'Parola trebuie sa contina minim 8 caractere',
                      ]"
                      required
                  ></v-text-field>
                </v-col>

                <v-col sm="6" cols="12">
                  <v-text-field v-model="newUser.pass2" label="Repeta parola *" type="password"
                      :rules="[
                          v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special',
                          passwordConfirmationRule
                      ]"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-checkbox
                  :rules="requiredTermeni"
                  :value="true" v-model="termeniConditii"
                  label="Am citit si sunt de acord cu termenii si conditiile"
                  type="checkbox" required
                  readonly @click="termeniConditiiModal = true"
              ></v-checkbox>
            </v-container>
          </v-form>

          <div class="text-right mt-5">
            <v-btn color="primary" large class="mr-3" @click="regAccount" :loading="loading">
              Inregistreaza
            </v-btn>
          </div>
        </v-container>

      </v-card>
    </v-dialog>
  </v-row>

  <v-dialog v-model="termeniConditiiModal" width="600px">
    <v-card v-if="termeniConditiiPage">
      <v-card-title>
        <span class="text-h5">{{ termeniConditiiPage.name }}</span>
      </v-card-title>
      <v-card-text v-html="termeniConditiiPage.body"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="termeniConditiiModal = false; termeniConditii = false">
          Nu sunt deacord
        </v-btn>
        <v-btn color="green darken-1" text @click="termeniConditiiModal = false; termeniConditii = true">
          De acord
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import { authApi } from "@auth/api";
import { PagesActions, PagesGetters } from "@pages/shared/pages.store";


export default {
  name: "RegisterModal",

  props: [],

  data () {
    return {
      dialog: false,
      loading: false,

      alertFail: false,

      termeniConditii: false,
      termeniConditiiModal: false,
      termeniConditiiSlug: 'termeni-si-conditii',

      valid: false,
      items: {
        specialitati: null,
        grade_didactice: null,
        judete: null
      },

      newUser: {
        email: null,
        pass1: null,
        pass2: null,
        nume: null,
        prenume: null,
        specialitate_1: null,
        grad_profesional_1: null,
        specialitate_2: null,
        grad_profesional_2: null,
        cuim: null,
        judet: null,
        telefon: null,
      },

      requiredTermeni: [v => !!v || 'Trebuie sa acceptati Termeni si conditii de utilizare si ca sunteti de acord cu respectarea lor.'],
    }
  },

  async mounted() {
    await PagesActions.fetchPage(this.termeniConditiiSlug);

    this.specialitatiSiGrade();
    this.getJudete();
  },

  computed: {
    termeniConditiiPage: PagesGetters.getPage,

    passwordConfirmationRule() {
      return () => (this.newUser.pass1 === this.newUser.pass2) || 'Cele 2 parole trebuie sa fie identice'
    },
    bothOrNone() {
      return !((this.newUser.specialitate_2 && !this.newUser.grad_profesional_2) || (!this.newUser.specialitate_2 && this.newUser.grad_profesional_2))  || 'Camp obligatoriu'
    },
  },

  methods: {
    openModal() {
      this.dialog = true
    },

    async regAccount() {
      this.loading = true
      if( this.$refs.form.validate() ) {
        const response = await authApi.registerUser(this.newUser);
        if (response.status) {
          this.dialog = false
          this.$refs.form.reset()
          this.$emit('alertSucces')
        }
        else {
          this.alertFail = true
        }
      }
      this.loading = false
    },

    async getJudete() {
      const judete = await authApi.getJudete();
      this.items.judete = judete.data.map(item => {
        return {
          text: item['label'] + ' (' + item['code'] + ')',
          value: item['label']
        }
      });
    },

    async specialitatiSiGrade() {
      const specialitati = await authApi.getSpecialitati();
      if (specialitati.status) {
        this.items.specialitati = specialitati.data
      }

      const grade = await authApi.getGrade();
      if (grade.status) {
        this.items.grade_didactice = grade.data
      }
    }
  }
}
</script>