import Surveys from "./views/Surveys";

const Routes = [
    {
        path: "/survey-questions",
        name: "admin-survey-questions",
        component: Surveys,
        meta: {
            title: 'Intrebari sondaj',
        }
    },
]

export default Routes;