var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.courseData)?_c('v-container',[_c('h2',{staticClass:"mb-5"},[_vm._v(" Articole curs: "+_vm._s(_vm.courseData.name)+" "),_c('v-btn',{attrs:{"rounded":"","small":"","color":"primary","dark":"","absolute":"","top":"","right":"","to":{name: 'admin-courses'}}},[_vm._v(" Vezi toate cursurile ")])],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"no-data-text":"Niciun articol adaugat","items":_vm.courseData['articles'],"mobile-breakpoint":"800"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0",attrs:{"to":{name: 'admin-article', params: {id: item.id}},"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-page-next-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalii articol")])])],1)]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(item.Details)+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"180px"}},[_c('a',{attrs:{"href":item.URL,"target":"_new"}},[_vm._v(_vm._s(item.URL))])])]}}],null,false,2360742096)}),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',_vm._g({staticClass:"ml-auto ma-3",attrs:{"color":"primary","dark":""}},on),[_vm._v(" Adauga articol pentru acest curs "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline")])],1)],1)]}}],null,false,2050434070),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[(_vm.editedItem.id)?_c('span',[_vm._v("Edit "+_vm._s(_vm.editedItem.id))]):_c('span',[_vm._v("Adaugare articol")])]),_c('v-form',{ref:"formAddArticle",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Titlu articol","autofocus":"","rules":[ function (v) { return !!v || 'Introduceti numele articolului'; }]},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Anuleaza")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.saveItem()}}},[_vm._v("Salveaza")])],1)],1)],1)],1)],1):_c('v-progress-linear',{attrs:{"indeterminate":"","color":"indigo"}})}
var staticRenderFns = []

export { render, staticRenderFns }