<template>
  <div>
    <h2>Logout</h2>
    Redirecting...
  </div>
</template>
<script>
import { AuthActions } from "@auth/shared/auth.store";

export default {
  data() {
    return {
      loading: false,
    }
  },
  async created() {
    await AuthActions.logoutUser();
  },
  methods: {

  }
}
</script>