import axios from "axios";


const getAll = async () => {
    try {
        const response = await axios.get("/api/admin/organizatori/");
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getOne = async (id) => {
    try {
        const response = await axios.get("/api/admin/organizator/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const addOne = async (data) => {
    try {
        const response = await axios.post("/api/admin/organizator/", data, { headers: { 'Content-Type': `multipart/form-data;` }});
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const updateOne = async (id, data) => {
    try {
        const response = await axios.post("/api/admin/organizator/" + id, data, { headers: { 'Content-Type': `multipart/form-data;` }});
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const deleteOne = async (id) => {
    try {
        const response = await axios.delete("/api/admin/organizator/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}


export default {
    getAll,
    getOne,
    addOne,
    updateOne,
    deleteOne
}