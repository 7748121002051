import Vue from "vue";
import { accountApi } from "@account/api";

const initialState = {
    stateStatus: false,

    loadingCertifications: false,
    certifications: [],

    loadingSurveyQuestions: false,
    surveyQuestions: []
}

const state = Vue.observable({ ...initialState });

const Getters = {
    getLoadingCertifications: () => state.loadingCertifications,
    getCertifications: () => state.certifications,

    getLoadingSurveyQuestions: () => state.loadingSurveyQuestions,
    getSurveyQuestions: () => state.surveyQuestions,
}

const Mutations = {
    setLoadingCertifications: (payload) => {
        state.loadingCertifications = payload;
    },
    setCertifications: (payload) => {
        state.certifications = payload;
    },

    setLoadingSurveyQuestions: (payload) => {
        state.loadingSurveyQuestions = payload;
    },
    setSurveyQuestions: (payload) => {
        state.surveyQuestions = payload;
    },
    setSurveyToCertificate: (courseId) => {
        state.certifications = state.certifications.map(item => {
            if (item.courseId === courseId) {
                return {...item, survey: true }
            } else {
                return item
            }
        });
    },
}

const Actions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await Actions.fetchCertifications();
            await Actions.fetchSurveyQuestions();
        }
        state.stateStatus = true;
    },
    fetchCertifications: async () => {
        Mutations.setLoadingCertifications(true);
        const certifications = await accountApi.getAllCertifications();
        Mutations.setLoadingCertifications(false);
        Mutations.setCertifications(certifications.data);
    },

    fetchSurveyQuestions: async () => {
        Mutations.setLoadingSurveyQuestions(true);
        const surveyQuestions = await accountApi.getSurveyQuestions();
        Mutations.setLoadingSurveyQuestions(false);
        Mutations.setSurveyQuestions(surveyQuestions.data);
    },
}

export const CertificationsGetters = Getters;
export const CertificationsMutations = Mutations;
export const CertificationsActions = Actions;
