var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.courses)?_c('v-container',[_c('h2',{staticClass:"mb-5"},[_vm._v("Cursuri")]),_c('v-card',[_c('v-card-title',[_vm._v(" Lista "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cauta...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.customFilterRomoveDiacritics,"no-data-text":"Niciun curs adaugat","items":_vm.courses,"mobile-breakpoint":"800"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0",attrs:{"icon":"","to":{name: 'admin-course', params: {id: item.id}},"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editeaza curs")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0 ml-5",attrs:{"small":"","to":{name: 'admin-course-articles', params: {id: item.id}},"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Articole ")])]}}],null,true)},[_c('span',[_vm._v("Articole curs")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","href":"","color":"primary"},on:{"click":function($event){return _vm.downloadCertificates(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-certificate-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Descarca lista absolventi")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","href":"","color":"primary"},on:{"click":function($event){return _vm.downloadSurveys(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-counter")])],1)]}}],null,true)},[_c('span',[_vm._v("Descarca sodaj absolventi")])])],1)]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(item.Details)+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"180px"}},[_c('a',{attrs:{"href":item.URL,"target":"_new"}},[_vm._v(_vm._s(item.URL))])])]}}],null,false,1895051082)}),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',_vm._g({staticClass:"ml-auto ma-3",attrs:{"color":"primary","dark":""}},on),[_vm._v(" Adauga curs nou "),_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline")])],1)],1)]}}],null,false,277703195),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[(_vm.editedItem.id)?_c('span',[_vm._v("Edit "+_vm._s(_vm.editedItem.id))]):_c('span',[_vm._v("Creare curs")])]),_c('v-form',{ref:"formAddCurs",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Name","autofocus":"","rules":[ function (v) { return !!v || 'Introduceti numele cursului'; }]},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.showEditDialog()}}},[_vm._v("Anuleaza")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.saveItem()}}},[_vm._v("Salveaza")])],1)],1)],1)],1)],1):_c('v-progress-linear',{attrs:{"indeterminate":"","color":"indigo"}})}
var staticRenderFns = []

export { render, staticRenderFns }