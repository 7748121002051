<template>
  <v-container v-if="users">
    <h2 class="mb-5">Utilizatori</h2>

    <v-card>
      <v-card-title>
        Lista
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cauta..." single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :headers="headers" :search="search" :custom-filter="customFilterRomoveDiacritics" no-data-text="Niciun utilizator activ" :items="users" mobile-breakpoint="800" class="elevation-0">
        <template #item.roles="{ item }">
          <v-chip small class="mr-2" v-for="role in item.roles" :key="role">{{ role }}</v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="showUserDetails(item.id)" icon class="mx-0">
                  <v-icon>mdi-account-question-outline</v-icon>
                </v-btn>
              </template>
              <span>Detalii utilizator</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="editUser(item.id)" icon class="mx-0" color="primary">
                  <v-icon>mdi-clipboard-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editeaza utilizator</span>
            </v-tooltip>

            <v-tooltip top v-if="user.id !== item.id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="blocheazaUserModal(item)" icon class="mx-0" :color="(item.is_active)?'green':'red'" :loading="button_loading===item.id">
                  <v-icon v-if="item.is_active">mdi-account</v-icon>
                  <v-icon v-else>mdi-account-off</v-icon>
                </v-btn>
              </template>
              <span v-if="item.is_active">Blocheaza utilizator</span>
              <span v-else>Deblocheaza utilizator</span>
            </v-tooltip>
          </div>
        </template>

      </v-data-table>

    </v-card>

    <v-btn color="green lighten-2" dark class="mt-3 mb-3" @click="addNewUser">
      Adauga un user
      <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
    </v-btn>


    <v-dialog v-model="dialogDisableUser" persistent max-width="450">
      <v-card>
        <v-card-title>
          <span v-if="userStatusChange && userStatusChange.is_active">Sigur doriti blocarea acestui utilizator?</span>
          <span v-else>Sigur doriti deblocarea acestui utilizator?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDisableUser=false;button_loading=false">
            Anuleaza
          </v-btn>
          <v-btn color="green darken-1" text @click="blocheazaUser" :loading="loading">
            Da
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="600">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Detalii utilizator</v-toolbar>
          <v-card-text>
            <div class="mt-8" v-if="userDetails">
              <p>Nume: <strong>{{userDetails.first_name}} {{userDetails.last_name}}</strong></p>
              <p>Email: <strong>{{userDetails.email}}</strong></p>
              <p v-if="userDetails.cuim">CUIM: <strong>{{userDetails.cuim}}</strong></p>
              <p v-if="userDetails.phone">Telefon: <strong>{{userDetails.phone}}</strong></p>
              <p v-if="userDetails.judet">Judet: <strong>{{userDetails.judet}}</strong></p>
              <p v-if="userDetails.specialitate">Specialitate 1: <strong>{{userDetails.specialitate}}</strong>, grad <strong>{{userDetails.gradProfesional}}</strong></p>
              <p v-if="userDetails.specialitate2">Specialitate 2: <strong>{{userDetails.specialitate2}}</strong>, grad <strong>{{userDetails.gradProfesional2}}</strong></p>
              <p>Creat la: <strong>{{userDetails.created_at}}</strong></p>
              <div class="mt-3">
                <v-chip small class="mr-2" v-for="role in userDetails.roles" :key="role">{{ role }}</v-chip>
              </div>
            </div>

            <div class="text-center mt-5" v-else>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeDialog">Inchide</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="dialogAddUser" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span v-if="isUserEdited">Editare utilizator</span>
          <span v-else>Adaugare utilizator</span>
        </v-card-title>

        <v-form
            ref="formAddUser"
            v-model="valid"
            lazy-validation
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" xs="12" v-if="!isUserEdited">
                <v-text-field v-model="editedItem.newPassword" :rules="[ v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special']" label="Parola *" type="password" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" xs="12" v-if="!isUserEdited">
                <v-text-field v-model="editedItem.newPasswordConfirm" :rules="[v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special', passwordConfirmationRule]" label="Repeta parola *" type="password" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-text-field v-model="editedItem.first_name" :counter="30" :rules="required" label="Nume *" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-text-field v-model="editedItem.last_name" :counter="30" :rules="required" label="Prenume *" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-text-field :disabled="isUserEdited" v-model="editedItem.email" :rules="emailRules" label="E-mail *" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-text-field v-model="editedItem.phone" label="Telefon"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-autocomplete v-model="editedItem.judet" label="Judet" :items="judete"></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-text-field v-model="editedItem.cuim" label="CUIM">
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-select v-model="editedItem.specialitate" :items="specialitati" label="Specialitatea 1"></v-select>
              </v-col>

              <v-col sm="6" cols="12" xs="12">
                <v-select v-model="editedItem.gradProfesional" :items="grade_didactice" label="Gradul profesional didactic"></v-select>
              </v-col>

              <v-col cols="12" sm="6" xs="12">
                <v-select clearable v-model="editedItem.specialitate2" :items="specialitati" label="Specialitatea 2"></v-select>
              </v-col>

              <v-col sm="6" cols="12" xs="12">
                <v-select clearable v-model="editedItem.gradProfesional2" :items="grade_didactice" label="Gradul profesional didactic"></v-select>
              </v-col>

              <v-col md="6">
                <v-autocomplete v-model="selectieRoluri" @change="asociazaRol" :items="roles" placeholder="Asociaza roluri"></v-autocomplete>
              </v-col>

              <v-col md="6">
                <div v-if="editedItem.roles && editedItem.roles.length">
                  <v-chip elevation="10" class="ma-2" color="primary" text-color="white" close @click:close="removeRol(rol)" v-for="rol in editedItem.roles" :key="rol.id">
                    {{rol}}
                  </v-chip>
                </div>

                <div v-else>Niciun rol asociat.</div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModalUser">Anuleaza</v-btn>
          <v-btn color="blue darken-1" text @click="saveUser" :loading="loading">Salveaza</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <v-snackbar v-model="snackbar" :color="snackbarColor" :vertical="true" top right :timeout="3000">
      {{ snackbarMassage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>


  </v-container>
  <v-progress-linear v-else indeterminate color="indigo"></v-progress-linear>
</template>

<script>
import { UsersActions, UsersGetters, UsersMutations } from "@admin/modules/users/shared/users.store";
import {authApi} from "../../../../auth/api";

export default {
  name: "Users",

  props: ["user", "isAuthenticated"],

  data() {
    return {
      dialog: false,
      dialogAddUser: false,
      dialogDisableUser: false,
      valid: false,

      snackbar: false,
      snackbarColor: null,
      snackbarMassage: '',

      search: '',

      button_loading: false,
      userStatusChange: null,
      loading: false,

      editedItem: {
        'roles': []
      },
      required: [v => !!v || 'Camp obligatoriu'],
      emailRules: [
        v => !!v || 'Camp obligatoriu',
        v => /.+@.+\..+/.test(v) || 'E-mailul nu este valid',
      ],

      specialitati: [],
      grade_didactice: [],
      judete: [],

      selectieRoluri: null,

      headers: [
        { text: 'Nume utilizator', value: 'nested.full_name' },
        { text: 'Email', value: 'email' },
        { text: 'Roluri', value: 'roles', sortable: false },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },

  async mounted() {
    await UsersActions.init();
    await this.loadNeededData()
  },

  computed: {
    users(){
      let users = UsersGetters.getUsers();
      return users.map(user => {
        return { ...user, nested: { full_name: user.first_name + ' ' + user.last_name }}
      });
    },
    userDetails: UsersGetters.getUser,
    roles: UsersGetters.getRoles,

    passwordConfirmationRule() {
      return () => (this.editedItem.newPassword === this.editedItem.newPasswordConfirm) || 'Cele 2 parole trebuie sa fie identice'
    },
    bothOrNone() {
      return !((this.editedItem.specialitate2 && !this.editedItem.gradProfesional2) || (!this.editedItem.specialitate2 && this.editedItem.gradProfesional2))  || 'Camp obligatoriu'
    },
    isUserEdited() {
      return !!this.editedItem.id;
    }
  },

  methods: {
    async loadNeededData(){
      const specialitati = await authApi.getSpecialitati();
      if (specialitati.status) {
        this.specialitati = specialitati.data
      }

      const grade = await authApi.getGrade();
      if (grade.status) {
        this.grade_didactice = grade.data
      }

      const judete = await authApi.getJudete();
      if (judete.status) {
        this.judete = judete.data.map(item => {
          return {
            text: item['label'] + ' (' + item['code'] + ')',
            value: item['label']
          }
        });
      }
    },

    async showUserDetails(id) {
      this.dialog = true;
      await UsersActions.fetchOneUser(id);
    },

    async editUser(id) {
      await UsersActions.fetchOneUser(id);
      this.editedItem = this.userDetails
      this.dialogAddUser = true;
    },

    blocheazaUserModal(item) {
      this.userStatusChange = item
      this.button_loading = item.id
      this.dialogDisableUser = true
    },

    async blocheazaUser() {
      this.loading = true

      let user = { ...this.userStatusChange }
      user.is_active = !user.is_active
      await UsersActions.updateUser(user.id, user);

      this.button_loading = false
      this.dialogDisableUser = false
      this.loading = false
    },

    async saveUser() {
      this.loading = true
      let editedItem = this.editedItem;

      if(this.$refs.formAddUser.validate()) {
        if( !editedItem.id ) { //adaugare user nou
          const addUser = await UsersActions.addUser(editedItem);
          if(addUser) {
            this.snackbarColor = 'success'
            this.snackbarMassage = 'Utilizatorul a fost adaugat!'
          }
          else {
            this.snackbarColor = 'danger'
            this.snackbarMassage = 'Acest email este folosit deja!'
          }
          this.snackbar = true
        }
        else { //edit user
          await UsersActions.updateUser(editedItem.id, editedItem);
        }

        this.dialogAddUser = false
        this.editedItem = {
          'roles': []
        }
      }
      this.loading = false
    },

    asociazaRol(rol) {
      this.$nextTick(() => {
        this.selectieRoluri = null
      })
      this.editedItem.roles.indexOf(rol) === -1 ? this.editedItem.roles.push(rol) : false
    },

    removeRol(rol) {
      this.editedItem.roles = this.editedItem.roles.filter((item) => item !== rol)
    },

    addNewUser() {
      this.dialogAddUser = true
    },

    closeDialog() {
      this.dialog = false;
      UsersMutations.setUser(null);
    },

    closeModalUser() {
      this.dialogAddUser = false
      this.editedItem = {
        'roles': []
      }
    },
    customFilterRomoveDiacritics(value, search) {
      if (search && value) {
        if (typeof value === 'string') {
          return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.toLowerCase());
        } else {
          return value == search;
        }
      }
    },
  }
}
</script>