import axios from "axios";


const getAllSpecializari = async () => {
    try {
        const response = await axios.get("/api/admin/specializari/");
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getOneSpecializare = async (id) => {
    try {
        const response = await axios.get("/api/admin/specializare/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const addOneSpecializare = async (data) => {
    try {
        const response = await axios.post("/api/admin/specializare/", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const updateOneSpecializare = async (id, data) => {
    try {
        const response = await axios.put("/api/admin/specializare/" + id, data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const deleteOneSpecializare = async (id) => {
    try {
        const response = await axios.delete("/api/admin/specializare/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}


const getAllGrade = async () => {
    try {
        const response = await axios.get("/api/admin/grade/");
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const addOneGrad = async (data) => {
    try {
        const response = await axios.post("/api/admin/grad/", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const updateOneGrad = async (id, data) => {
    try {
        const response = await axios.put("/api/admin/grad/" + id, data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const deleteOneGrad = async (id) => {
    try {
        const response = await axios.delete("/api/admin/grad/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}


export default {
    getAllSpecializari,
    getOneSpecializare,
    addOneSpecializare,
    updateOneSpecializare,
    deleteOneSpecializare,

    getAllGrade,
    addOneGrad,
    updateOneGrad,
    deleteOneGrad
}