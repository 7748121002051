import Courses from "./views/Courses.vue";
import Course from "./views/Course";

const Routes = [
    {
        path: "/courses",
        name: "admin-courses",
        component: Courses,
        meta: {
            menuTitle: 'Courses'
        },
    },
    {
        path: "/course/:id",
        name: "admin-course",
        component: Course
    },
]

export default Routes;