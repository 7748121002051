<template>
  <v-container v-if="items">
    <h2 class="mb-5">Coordonatori</h2>
    <v-card>
      <v-card-title>
        Lista
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cauta..." single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :headers="headers" :search="search" :custom-filter="customFilterRomoveDiacritics" no-data-text="Niciun coordonator adaugat" :items="items" mobile-breakpoint="800" class="elevation-0">
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="editItem(item)" icon class="mx-0" color="primary">
                  <v-icon>mdi-application-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Modifica</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" @click="deleteOrgModal(item)" color="pink">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Sterge</span>
            </v-tooltip>
          </div>
        </template>

      </v-data-table>
    </v-card>

    <v-btn color="green lighten-2" dark class="mt-3 mb-3" @click="addNewCoordonator">
      Adauga un coordonator
      <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
    </v-btn>

    <v-dialog v-model="dialogAdd" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span v-if="editedItem.id">Editare coordonator</span>
          <span v-else>Adaugare coordonator</span>
        </v-card-title>

        <v-form ref="formAddOrg" v-model="valid" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.name" label="Nume coordonator"
                    :rules="[
                              v => !!v || 'Numele coordonatorului este obligatoriu'
                          ]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <Filemanager v-model="editedItem.linkImages" :allowExtension="['jpg', 'jpeg', 'png']" type="select" single="true" btnLabel="Semnatura coordonator" />
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea v-model="editedItem.description" label="Descriere" auto-grow rows="2"
                    :rules="[
                              v => !!v || 'Introduceti descrierea'
                          ]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAdd=false">Anuleaza</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem" :loading="loading">Salveaza</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <v-dialog v-model="dialogDelete" persistent max-width="450">
      <v-card>
        <v-card-title>
          Sigur doriti stergerea acestui coordonator?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDelete=false;coordonatorToBeDeleted=null">
            Anuleaza
          </v-btn>
          <v-btn color="green darken-1" text @click="deleteCoordonator()" :loading="loading">
            Da
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-progress-linear v-else indeterminate color="indigo"></v-progress-linear>
</template>

<script>
import { coordonatoriApi } from "../api";
import FormData from "form-data";
import Filemanager from '@core/views/Filemanager';

export default {
  name: "Coordonatori",
  components: {
    Filemanager
  },
  data() {
    return {
      items: null,
      search: '',

      editedItem: {
        images: [],
        linkImages: []
      },

      coordonatorToBeDeleted: null,

      imageToUpload: null,
      uploadsDir: process.env.VUE_APP_UPLOADSDIR,

      dialogAdd: false,
      dialogDelete: false,
      valid: false,
      loading: false,

      headers: [
        { text: 'Nume coordonator', value: 'name' },
        { text: 'Descriere', value: 'description' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },

  mounted() {
    this.loadItems()
  },

  methods: {
    async loadItems() {
      const coordonatori = await coordonatoriApi.getAll();
      this.items = coordonatori.data;
    },

    async saveItem() {
      this.loading = true
      let editedItem = this.editedItem;
      let data = new FormData()
      if(this.imageToUpload) data.append('file', this.imageToUpload)
      data.append('data', JSON.stringify(this.editedItem))

      if( this.$refs.formAddOrg.validate() ) {
        if( !editedItem.id ) { //adaugare coordonator nou
          const response = await coordonatoriApi.addOne(data);
          if (response.status) {
            this.items.push(response.data);
            this.$refs.formAddOrg.reset()
            this.dialogAdd = false
          }
        }
        else { //editare coordonator
          const response = await coordonatoriApi.updateOne(editedItem.id, data);
          if (response.status) {
            this.items = this.items.map(item => item.id === editedItem.id ? response.data : item)
            this.dialogAdd = false
          }
        }
      }
      this.loading = false
    },

    async deleteCoordonator() {
      this.loading = true
      let id = this.coordonatorToBeDeleted.id
      const response = await coordonatoriApi.deleteOne(id);
      if (response.status) {
        this.items = this.items.filter(item => item.id !== id);
      }

      this.coordonatorToBeDeleted = null
      this.dialogDelete = false
      this.loading = false
    },

    deleteOrgModal(item) {
      this.coordonatorToBeDeleted = item
      this.dialogDelete = true
    },

    addNewCoordonator() {
      this.editedItem = {
        images: [],
        linkImages: []
      }
      this.dialogAdd = true
      if(this.$refs.formAddOrg) this.$refs.formAddOrg.reset()
    },

    editItem(item) {
      this.editedItem = {...item}
      this.dialogAdd = true
    },

    closeModal() {
      this.dialogAdd=false
      this.imageToUpload=null
    },
    customFilterRomoveDiacritics(value, search) {
      if (search && value) {
        if (typeof value === 'string') {
          return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.toLowerCase());
        } else {
          return value == search;
        }
      }
    },
  }
}
</script>