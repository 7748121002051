import axios from "axios";

const getAll = async () => {
    try {
        const response = await axios.get("/api/admin/pages");
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const addOne = async (data) => {
    try {
        const response = await axios.post("/api/admin/pages", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const updateOne = async (id, data) => {
    try {
        const response = await axios.put("/api/admin/pages/" + id, data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const deleteOne = async (id) => {
    try {
        const response = await axios.delete("/api/admin/pages/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export default {
    getAll,
    addOne,
    updateOne,
    deleteOne
}