<template>

  <v-container v-if="quizzes">

    <h2>Chestionare articol</h2>

    <v-card class="mt-5 mb-5">
      <v-data-table :headers="headers" no-data-text="Niciun chestionar adaugat" :items="quizzes" mobile-breakpoint="800" class="elevation-0">
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" color="primary" @click="openQuestionsModal(item)">
                  <v-icon>mdi-chat-question-outline</v-icon>
                </v-btn>
              </template>
              <span>Vizualizeaza intrebarile pentru acest chestionar</span>
            </v-tooltip>

<!--            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" @click="deleteItem(item)" color="pink">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Sterge acest chestionar</span>
            </v-tooltip>-->

          </div>
        </template>
        <template v-slot:item.details="{ item }">
          <div class="text-truncate" style="width: 180px">
            {{item.Details}}
          </div>
        </template>
        <template v-slot:item.url="{ item }">
          <div class="text-truncate" style="width: 180px">
            <a :href="item.URL" target="_new">{{item.URL}}</a>
          </div>
        </template>
      </v-data-table>

    </v-card>


    <QuestionsModal ref="questionsModal" />


    <!-- this dialog is used for both create and update -->
    <v-dialog v-model="dialog" max-width="800px">

      <template v-slot:activator="{ on }">
        <div class="d-flex" v-if="!quizzes.length">
          <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
            Adauga chestionar
            <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>
      </template>

      <v-card>
        <v-card-title>
          <span v-if="editedItem.id">Edit {{editedItem.id}}</span>
          <span v-else>Adaugare chestionar</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="editedItem.name" label="Titlu chestionar"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal()">Anuleaza</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem()" :loading="loading">Salveaza</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </v-container>


  <v-progress-linear v-else
                     indeterminate
                     color="indigo"
  ></v-progress-linear>

</template>


<script>
import { articleApi } from "@admin/modules/articles/api";
import QuestionsModal from "../components/QuestionsModal";

export default {
  name: "ArticleQuizes",

  props: ['articleId'],

  components: { QuestionsModal },

  data() {
    return {
      quizzes: null,

      dialog: false,
      questionsModal: false,
      loading: false,

      editedItem: {
        article_id: this.articleId
      },

      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Titlu chestionar', value: 'name' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },

  mounted() {
    this.loadArticleQuizzes()
  },

  methods: {
    async loadArticleQuizzes() {
      const articleQuizzes = await articleApi.getArticleQuizzes(this.articleId);
      this.quizzes = articleQuizzes.data;
      //console.log(articleQuizzes.data)
    },

    async saveItem() {
      this.loading = true
      let editedItem = this.editedItem;

      if (!editedItem.id) {
        const response = await articleApi.addOneQuiz(editedItem);
        if (response.status) {
          this.quizzes.push(response.data);
          console.log(response)
        }
      }
      this.editedItem = {
        article_id: this.articleId
      };
      this.dialog = false
      this.loading = false
    },

    closeModal() {
      this.dialog = false
    },

    openQuestionsModal(quiz) {
      this.$refs.questionsModal.openModal(quiz)
    },

  },

}
</script>