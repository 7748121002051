var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-container',[_c('h2',{staticClass:"mb-5"},[_vm._v("Organizatori")]),_c('v-card',[_c('v-card-title',[_vm._v(" Lista "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cauta...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.customFilterRomoveDiacritics,"no-data-text":"Niciun organizator adaugat","items":_vm.items,"mobile-breakpoint":"800"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-application-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0",attrs:{"icon":"","color":"pink"},on:{"click":function($event){return _vm.deleteOrgModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Sterge")])])],1)]}}],null,false,1609311303)})],1),_c('v-btn',{staticClass:"mt-3 mb-3",attrs:{"color":"green lighten-2","dark":""},on:{"click":_vm.addNewOrganizator}},[_vm._v(" Adauga un organizator "),_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline")])],1),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialogAdd),callback:function ($$v) {_vm.dialogAdd=$$v},expression:"dialogAdd"}},[_c('v-card',[_c('v-card-title',[(_vm.editedItem.id)?_c('span',[_vm._v("Editare organizator")]):_c('span',[_vm._v("Adaugare organizator")])]),_c('v-form',{ref:"formAddOrg",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Nume organizator","rules":[
                            function (v) { return !!v || 'Numele organizatorului este obligatoriu'; }
                        ]},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1),_c('v-card-text',[_c('Filemanager',{attrs:{"allowExtension":['jpg', 'jpeg', 'png'],"type":"select","single":"true","btnLabel":"Sigla organizator"},model:{value:(_vm.editedItem.linkImages),callback:function ($$v) {_vm.$set(_vm.editedItem, "linkImages", $$v)},expression:"editedItem.linkImages"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"label":"Descriere","auto-grow":"","rows":"2","rules":[
                            function (v) { return !!v || 'Introduceti descrierea'; }
                        ]},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v("Anuleaza")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.saveItem}},[_vm._v("Salveaza")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Sigur doriti stergerea acestui organizator? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogDelete=false;_vm.organizatorToBeDeleted=null}}},[_vm._v(" Anuleaza ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.deleteOrganizator()}}},[_vm._v(" Da ")])],1)],1)],1)],1):_c('v-progress-linear',{attrs:{"indeterminate":"","color":"indigo"}})}
var staticRenderFns = []

export { render, staticRenderFns }