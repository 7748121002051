import { AuthGetters, AuthActions, AuthMutations } from "@auth/shared/auth.store";

export const authGuard = async (to, from, next) => {
    if (to.meta && to.meta.isPublicPage && !to.meta.redirectToAccountPage) {
        return next();
    }
    if (!AuthGetters.isAuthenticated() && to.path !== "/auth/login") {
        return next("/auth/login");
    }

    // if is admin
    AuthMutations.SET_IS_ADMIN(to.path.substring(1, 6) === 'admin');
    let forbiden = await AuthActions.isForbiden('ROLE_ADMIN');
    if (forbiden && to.path != '/403-forbiden') {
        return next("/403-forbiden");
    }

    if (AuthGetters.isAuthenticated() && to.meta.redirectToAccountPage) {
        return next("/account");
    }

    return next();
}