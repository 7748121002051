<template>
  <div>
    <v-card class="mx-auto">
      <div class="pa-5">
      <h3>Diplomele mele</h3>

        <v-list two-line flat>
          <v-card>
            <template v-for="(certificate, key) in certifications">
              
              <template :ripple="false" v-if="certificate.status == 'finish'">
                <v-list-item :key="certificate.id" >
                  <v-list-item-avatar>
                    <v-icon :color="certificate['passCourse'] ? 'green' : 'red'" large v-text="certificate['passCourse'] ? 'mdi-check-decagram' : 'mdi-close-circle'"></v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ certificate.courseName }}</v-list-item-title>
                    <v-list-item-subtitle><v-icon small>mdi-calendar-check-outline</v-icon> {{ certificate.startAt }} - {{ certificate.receivedAt }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="downloadCertificate(certificate)" v-bind="attrs" v-on="on" v-if="certificate['passCourse']" color="blue-grey" small class="ma-2 white--text" fab>
                          <v-icon dark>
                            mdi-file-certificate-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Descarca diploma</span>
                    </v-tooltip>
                  </v-list-item-action>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" class="mr-2" :color="certificate['passCourse'] ? 'green' : 'red'" text-color="white">
                        {{ certificate.percentage }} <v-icon>mdi-percent</v-icon>
                      </v-chip>
                    </template>
                    <span>Procentaj</span>
                  </v-tooltip>

                </v-list-item>
              </template>

              <template v-else>
                <v-list-item :key="certificate.id">
                  <v-list-item-avatar>
                    <v-icon color="grey" large>mdi-pause-circle-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ certificate.courseName }}</v-list-item-title>
                    <v-list-item-subtitle>Mai ai {{ Object.keys(certificate.articlesToFinish).length }}
                      <template v-if="Object.keys(certificate.articlesToFinish).length == 1">articol</template>
                      <template v-else>articole</template>
                       de finalizat. Bafta!</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-chip class="mr-2" :to="{ name: 'course', params: { courseId: certificate.courseId } }" color="grey" text-color="white">
                    <v-icon class="mr-2">mdi-play-circle-outline</v-icon> CONTINUA
                  </v-chip>
                </v-list-item>
              </template>
              <v-divider :key="key"></v-divider>

            </template>

          </v-card>
        </v-list>

      </div>
    </v-card>

    <v-dialog v-model="surveyModal" v-if="surveyModal" scrollable persistent width="900">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Sondaj: {{ surveyModal.courseName }}
        </v-card-title>

        <v-card-text class="pt-4 font-weight-bold">
          <p class="title">Raspundeti la intrebarile de mai jos pentru a descarca diploma.</p>
          <div :id="'survey-' + surveyQuestion.id" class="mt-5" v-for="(surveyQuestion, index) in surveyQuestions" :key="surveyQuestion.id">
            <div :class="'subtitle-1 font-weight-bold'">{{ (index+1) }}. {{ surveyQuestion.name }}</div>

            <div class="rating">
              <template v-for="rating in ratingNumber">
                <div class="rating-header" v-if="rating == 1" :key="'header-' + rating">
                  <div class="yes subtitle-2">Da</div>
                  <div class="neutral subtitle-2">Neutru</div>
                  <div class="no subtitle-2">Nu</div>
                </div>
                <v-btn @click="surveyQuestion.value = rating" icon :class="rating == 3 ? ' mx-7' : 'mx-1'" :color="surveyQuestion.value === rating ? 'orange' : 'grey lighten-1'" :key="'rating-' + rating">
                  <v-icon x-large>{{ surveyQuestion.value === rating ? `mdi-numeric-${rating}-circle` : `mdi-numeric-${rating}-circle-outline`}}</v-icon>
                </v-btn>
              </template>
            </div>

          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" sm="6" xs="12">
              <v-btn color="success" :loading="surveyPreloader" @click="sendSurvey">TRIMITE SI DESCARCA</v-btn>
            </v-col>
            <v-col cols="12" sm="6" xs="12" class="text-right">
              <v-btn plain @click="surveyModal=null">Nu acum</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<style scoped>
.rating {
  display: inline-block;
  position: relative;
}
.rating button:hover {
  color: #999999!important;
}
.rating .rating-header {
  display: table;
  width: 100%;
}
.rating .rating-header div {
  display: table-cell;
  text-align: center;
  color: #a9a9a9;
}
.rating .rating-header div:first-child,
.rating .rating-header div:last-child {
  width: 33%;
}
</style>
<script>
import { accountApi } from "@account/api";
import { CertificationsMutations } from "@account/shared/account.store";

export default {
  props: ["user", "isAuthenticated", "certifications", "surveyQuestions"],
  data() {
    return {
      ratingNumber: 5,
      loading: false,
      surveyModal: null,
      surveyPreloader: false,

      APP_SERVER: process.env.VUE_APP_SERVER,
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    async sendSurvey() {
      const userAnswers = this.surveyQuestions;

      // color
      for (let x in userAnswers) {
        let el = document.getElementById('survey-' + userAnswers[x].id);
        el.style.color = 'inherit';
        if (!userAnswers[x].value) {
          setTimeout(function () {
            el.style.color = 'red';
          }, 300);
        }
      }

      // scroll and stop
      for (let x in userAnswers) {
        if (!userAnswers[x].value) {
          setTimeout(function () {
            let el = document.getElementById('survey-' + userAnswers[x].id);
            el.scrollIntoView({ behavior: 'smooth', block: 'end'});
          }, 300);
          return
        }
      }

      this.surveyPreloader = true;

      let courseId = this.surveyModal.courseId;
      let mapSurveyAnswers =  userAnswers.map(item => { return { id: item.id, value: item.value }});

      const surveyQuestions = await accountApi.sendSurvey(courseId, mapSurveyAnswers);
      if (surveyQuestions.status) {
        // to update certifications
        CertificationsMutations.setSurveyToCertificate(courseId);

        this.surveyModal = null;
        window.open(this.APP_SERVER + '/api/certification/course/' + courseId + '/download', '_blank');
      } else {
        alert(surveyQuestions.error)
      }
      this.surveyPreloader = false;
    },
    downloadCertificate(certificate) {
      if (!certificate.survey) {
        this.surveyModal = certificate;
        return;
      }
      window.open(this.APP_SERVER + '/api/certification/course/' + certificate.courseId + '/download', '_blank');
    }
  }
}
</script>