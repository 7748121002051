var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Creaza un cont")])],1),_c('v-container',[_c('v-alert',{attrs:{"value":_vm.alertFail,"outlined":"","type":"warning","text":"","transition":"scale-transition"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Exista deja un cont cu acest email! ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){_vm.alertFail=false}}},[_vm._v("OK")])],1)],1)],1),(_vm.items.specialitati && _vm.items.grade_didactice)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Email *","rules":[
                          function (v) { return !!v || 'E-mail obligatoriu'; },
                          function (v) { return /.+@.+/.test(v) || 'E-mailul trebuie sa fie valid'; } ],"required":""},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nume *","rules":[
                          function (v) { return !!v || 'Introduceti numele'; }
                      ],"required":""},model:{value:(_vm.newUser.nume),callback:function ($$v) {_vm.$set(_vm.newUser, "nume", $$v)},expression:"newUser.nume"}})],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Prenume *","rules":[
                          function (v) { return !!v || 'Introduceti prenumele'; }
                      ],"required":""},model:{value:(_vm.newUser.prenume),callback:function ($$v) {_vm.$set(_vm.newUser, "prenume", $$v)},expression:"newUser.prenume"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.items.specialitati,"label":"Specialitatea 1 *","rules":[
                          function (v) { return !!v || 'Alegeti specialitatea'; }
                      ],"required":""},model:{value:(_vm.newUser.specialitate_1),callback:function ($$v) {_vm.$set(_vm.newUser, "specialitate_1", $$v)},expression:"newUser.specialitate_1"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.items.grade_didactice,"label":"Gradul profesional didactic *","rules":[
                          function (v) { return !!v || 'Alegeti gradul didactic'; }
                      ],"required":""},model:{value:(_vm.newUser.grad_profesional_1),callback:function ($$v) {_vm.$set(_vm.newUser, "grad_profesional_1", $$v)},expression:"newUser.grad_profesional_1"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-select',{attrs:{"clearable":"","items":_vm.items.specialitati,"label":"Specialitatea 2","rules":[_vm.bothOrNone]},model:{value:(_vm.newUser.specialitate_2),callback:function ($$v) {_vm.$set(_vm.newUser, "specialitate_2", $$v)},expression:"newUser.specialitate_2"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-select',{attrs:{"clearable":"","items":_vm.items.grade_didactice,"label":"Gradul profesional didactic","rules":[_vm.bothOrNone]},model:{value:(_vm.newUser.grad_profesional_2),callback:function ($$v) {_vm.$set(_vm.newUser, "grad_profesional_2", $$v)},expression:"newUser.grad_profesional_2"}})],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":"CUIM *","rules":[
                          function (v) { return /^\d+$/.test(v) || 'Doar cifre'; }
                      ],"required":""},model:{value:(_vm.newUser.cuim),callback:function ($$v) {_vm.$set(_vm.newUser, "cuim", $$v)},expression:"newUser.cuim"}})],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Judet *","items":_vm.items.judete,"rules":[
                          function (v) { return !!v || 'Alegeti judetul'; }
                      ],"required":""},model:{value:(_vm.newUser.judet),callback:function ($$v) {_vm.$set(_vm.newUser, "judet", $$v)},expression:"newUser.judet"}})],1),_c('v-col',{attrs:{"sm":"4","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Telefon *","rules":[
                          function (v) { return !!v || 'Introduceti telefonul'; }
                      ],"required":""},model:{value:(_vm.newUser.telefon),callback:function ($$v) {_vm.$set(_vm.newUser, "telefon", $$v)},expression:"newUser.telefon"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Parola *","type":"password","rules":[
                          function (v) { return !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special'; },
                          function (v) { return (v || '').length >= 8 || 'Parola trebuie sa contina minim 8 caractere'; } ],"required":""},model:{value:(_vm.newUser.pass1),callback:function ($$v) {_vm.$set(_vm.newUser, "pass1", $$v)},expression:"newUser.pass1"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Repeta parola *","type":"password","rules":[
                          function (v) { return !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special'; },
                          _vm.passwordConfirmationRule
                      ],"required":""},model:{value:(_vm.newUser.pass2),callback:function ($$v) {_vm.$set(_vm.newUser, "pass2", $$v)},expression:"newUser.pass2"}})],1)],1),_c('v-checkbox',{attrs:{"rules":_vm.requiredTermeni,"value":true,"label":"Am citit si sunt de acord cu termenii si conditiile","type":"checkbox","required":"","readonly":""},on:{"click":function($event){_vm.termeniConditiiModal = true}},model:{value:(_vm.termeniConditii),callback:function ($$v) {_vm.termeniConditii=$$v},expression:"termeniConditii"}})],1)],1):_vm._e(),_c('div',{staticClass:"text-right mt-5"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","large":"","loading":_vm.loading},on:{"click":_vm.regAccount}},[_vm._v(" Inregistreaza ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.termeniConditiiModal),callback:function ($$v) {_vm.termeniConditiiModal=$$v},expression:"termeniConditiiModal"}},[(_vm.termeniConditiiPage)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.termeniConditiiPage.name))])]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.termeniConditiiPage.body)}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.termeniConditiiModal = false; _vm.termeniConditii = false}}},[_vm._v(" Nu sunt deacord ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.termeniConditiiModal = false; _vm.termeniConditii = true}}},[_vm._v(" De acord ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }