import Vue from 'vue'
import VueRouter from "vue-router";
import axios from "axios";
import router from "./router";
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueGtag from "vue-gtag";

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_SERVER;
axios.defaults.withCredentials = true

export const EventBus = new Vue();

Vue.use(VueGtag, {
  config: { id: "UA-217476651-1" }
}, router);

// for tinyMCE & filemanager
window.onmessage = function(e) {
  if (e.data) {
    if (e.data.type === 'tiny') {
      // check e.origin
      if (window.tinymce && window.tinymce.activeEditor) {
        window.tinymceCallBackURL = e.origin + e.data.path;
        window.filemanagerSelected = e.data.path;
        window.tinymce.activeEditor.windowManager.close();
      }
    } else if (e.data.type === 'filemanager') {
      EventBus.$emit('filemanagerSelected', e.data.path);
    }
  }
}
// for tinyMCE & filemanager

import { AuthActions } from "@auth/shared/auth.store";
AuthActions.init();

Vue.use(VueRouter);

new Vue({
  async beforeCreate() {
    await AuthActions.loadUser();
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
