import Users from "./views/Users";

const Routes = [
    {
        path: "/users",
        name: "admin-users",
        component: Users,
        meta: {
            title: 'Utilizatori',
        }
    },
]

export default Routes;