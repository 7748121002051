import axios from "axios";

const getAllCertifications = async () => {
    try {
        const response = await axios.get("/api/user/certifications");
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

const getSurveyQuestions = async () => {
    try {
        const response = await axios.get("/api/survey-questions");
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

const sendSurvey = async (courseId, data) => {
    try {
        const response = await axios.post(`/api/survey/${courseId}`, data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}


export default {
    getAllCertifications,
    getSurveyQuestions,
    sendSurvey
}