<template>
  <div>
    <h1>Profilul meu</h1>

    <v-row>
      <v-col cols="12" sm="3" xs="12">

        <v-card class="mx-auto" max-width="300">
          <v-list rounded>
            <v-list-item-group color="primary">

              <v-list-item :to="{ name: 'myAccount' }" exact>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ user.first_name }} {{ user.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="ma-5"></v-divider>

              <v-list-item v-for="(menu, i) in menus" :key="i" :to="{ name: 'myAccountComponent', params: { componentName: menu.name }}" exact>
                <v-list-item-icon>
                  <v-icon v-text="menu.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="menu.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

             <v-list-item :to="{ name: 'user-logout' }">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-divider class="ma-5"></v-divider>

            <v-list-item-group color="primary" v-if="user['roles'].includes('ROLE_ADMIN')">
              <v-list-item :to="{ name: 'admin-dashboard' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Admin panel</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

          </v-list>
        </v-card>

      </v-col>

      <v-col cols="12" sm="9" xs="12">
        <component
            :is="extractComponent"
            :user="user"
            :surveyQuestions="surveyQuestions"
            :isAuthenticated="isAuthenticated"
            :certifications="certifications"
        ></component>
      </v-col>
    </v-row>

  </div>
</template>
<script>
import { CertificationsActions, CertificationsGetters } from "@account/shared/account.store";

import ProfileForm from "./components/ProfileForm";
import Certifications from "./components/Certifications";

export default {
  props: ["user", "isAuthenticated"],
  data() {
    return {
      valid: false,
      defaultComponentName: 'ProfileForm',
      componentName: this.$route.params.componentName ? this.$route.params.componentName : null,

      menus: [
        { text: 'Diplomele mele', icon: 'mdi-certificate', name: 'certifications', component: 'Certifications' },
      ],
    }
  },
  watch: {
    '$route.params.componentName': function (componentName) {
      this.componentName = componentName ?? null;
    }
  },
  computed: {
    certifications: CertificationsGetters.getCertifications,
    surveyQuestions: CertificationsGetters.getSurveyQuestions,

    extractComponent() {
      for (const menu of this.menus) {
        if (menu.name && this.componentName && menu.name.toLowerCase() == this.componentName.toLowerCase()) {
          return menu.component;
        }
      }
      return this.defaultComponentName; // default
    }
  },
  async mounted() {
    await CertificationsActions.init();
  },
  methods: {
  },
  components: {
    ProfileForm,
    Certifications
  }
}
</script>