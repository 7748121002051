<template>
  <div>
  <v-container v-if="surveys">
    <h2 class="mb-5">Intrebari sondaj</h2>
    <v-card>
      <v-card-title>
        Lista
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cauta..." single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :headers="headers" :search="search" :custom-filter="customFilterRomoveDiacritics" no-data-text="Nu sunt inregistrari" :items="surveysOrderByPosition" mobile-breakpoint="800" class="elevation-0">
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="editSurvey(item)" icon class="mx-0" color="primary">
                  <v-icon>mdi-clipboard-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editeaza</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :loading="item.id === deletePreloader" v-bind="attrs" v-on="on" @click="deleteSurvey(item)" icon class="mx-0" color="pink">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Sterge</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-btn color="green lighten-2" @click="addSurvey" dark class="mt-3 mb-3">
      Intrebare noua
      <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
    </v-btn>
  </v-container>

  <v-progress-linear v-else indeterminate color="indigo"></v-progress-linear>

  <v-dialog v-model="dialog" persistent scrollable max-width="900">
      <v-card tile>
        <v-toolbar elevation="1" style="z-index: 2" flat color="white">
          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="text-h5 py-4" v-text="survey.id ? 'Editeaza' : 'Adauga'"></span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="!valid" :loading="savePreloader" color="primary" @click="save">{{ survey.id ? 'SALVEAZA' : 'ADAUGA' }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="pt-3">
          <v-form v-model="valid">
            <v-row>
              <v-col cols="10">
                <v-text-field v-model="survey.name" :rules="required" label="Nume" required></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="survey.position" :rules="required" label="Position" required></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { SurveysActions, SurveysMutations, SurveysGetters } from "@admin/modules/surveys/shared/surveys.store";

export default {
  name: "Surveys",
  data() {
    return {
      dialog: false,
      savePreloader: false,
      deletePreloader: false,
      valid: false,
      search: '',

      required: [
        v => !!v || 'Camp obligatoriu'
      ],

      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nume', value: 'name' },
        { text: 'Pozitie', value: 'position' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },
  async mounted() {
    await SurveysActions.init();
  },
  computed: {
    surveys: SurveysGetters.getCollections,
    survey: SurveysGetters.getItem,

    surveysOrderByPosition() {
      if (this.surveys) {
        let surveys = this.surveys;
        return surveys.sort(function(a, b){
          if(a.position < b.position) { return -1; }
          if(a.position > b.position) { return 1; }
          return 0;
        })
      }
      return [];
    },
  },
  methods: {
    editSurvey(survey) {
      this.dialog = true;
      SurveysMutations.setItem({ ...survey });
    },
    addSurvey() {
      this.dialog = true;
      SurveysMutations.setItem({
        name: '',
        position: ''
      });
    },
    async save() {
      this.savePreloader = true;
      const data = {
        name: this.survey.name,
        position: this.survey.position
      }
      if (this.survey.id) {
        await SurveysActions.updateItem(this.survey.id, data);
      } else {
        await SurveysActions.addItem(data);
      }
      this.savePreloader = false;
      this.closeModal();
    },
    async deleteSurvey(survey) {
      this.deletePreloader = survey.id;
      if (confirm("Sigur doriți să ștergeți acest sondaj?")) {
        await SurveysActions.deleteItem(survey.id);
      }
      this.deletePreloader = false;
    },
    closeModal() {
      this.dialog = false;
      SurveysMutations.setItem({});
    },
    customFilterRomoveDiacritics(value, search) {
      if (search && value) {
        if (typeof value === 'string') {
          return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.toLowerCase());
        } else {
          return value == search;
        }
      }
    },
  }
}
</script>