<template>
  <div>
    <h2 class="text-center">EMCB</h2>

    <v-container>
      <div style="margin: 0 auto; max-width: 450px">

        <v-card class="mx-auto mb-5">
          <v-card-title class="text-h6 font-weight-regular justify-space-between">
            <span><v-icon>mdi-form-textbox-password</v-icon> Recuperare parola</span>
          </v-card-title>

          <v-alert type="success" class="ml-2 mr-2" dense outlined dismissible v-model="alertSuccess">
            <p>Parola Dvs. a fost schimbata cu succes. Click pe butonul de mai jos pentru a va loga!</p>
            <v-btn color="primary" large :to="{ name: 'user-login' }">
              LOGARE
            </v-btn>
          </v-alert>

          <v-form ref="recuperarePassForm" v-model="valid" lazy-validation @submit.prevent>
            <v-card-text>
              <v-text-field v-model="email" :rules="emailRules" label="E-mail" required @keyup.enter="sendRecoveryEmail"></v-text-field>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-text>
              <v-btn :disabled="!valid" large left color="success" :loading="loading" class="mr-4" @click="sendRecoveryEmail">
                TRIMITE
              </v-btn>

              <v-btn color="primary" text large class="float-right" :to="{ name: 'user-login' }">
                INAPOI
              </v-btn>
            </v-card-text>
          </v-form>

        </v-card>

      </div>
    </v-container>


    <v-dialog v-model="dialogCod" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Resetare parola
        </v-card-title>

        <v-snackbar v-model="alertMessage">
          {{ message }}

          <v-btn color="light-green lighten-3" text @click="alertMessage = false" class="ml-5">
            Inchide
          </v-btn>
        </v-snackbar>

        <v-card-text class="mt-5" v-if="showPassReset">
          <p>Introduceti noua parola:</p>

          <v-form ref="resetPassForm" v-model="validReset" lazy-validation @submit.prevent>
            <v-row>
              <v-col sm="6" cols="12">
                <v-text-field v-model="pass1" label="Parola *" type="password"
                              :rules="[
                          v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special',
                          v => (v || '').length >= 8 || 'Parola trebuie sa contina minim 8 caractere',
                      ]"
                              required
                ></v-text-field>
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field v-model="pass2" label="Repeta parola *" type="password"
                              :rules="[
                          v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special',
                          passwordConfirmationRule
                      ]"
                              required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="mt-7 mb-3"></v-divider>
            <v-card-text>
              <v-btn large left color="success" :loading="loadingPassReset" class="mr-4" @click="resetPassword">
                SCHIMBA PAROLA
              </v-btn>
            </v-card-text>
          </v-form>
        </v-card-text>

        <v-card-text class="mt-5" v-else>
          <p>Introduceti codul primit pe adresa de email pentru a va reseta parola (acesta este valabil pentru urmatoarele 20 minute):</p>

          <v-row>
            <v-col>
              <v-text-field v-model="codInput" :loading="codInputLoading" :disabled="codInputLoading" label="Cod resetare parola" @input="verificaCod"></v-text-field>
            </v-col>
          </v-row>

          <p>(daca nu gasiti email-ul in Inbox, verificati si folderul de Spam)</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="resetPassRecovery"
          >
            Inchide
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {authApi} from "../api";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      dialogCod: false,

      validReset: false,
      pass1: '',
      pass2: '',
      loadingPassReset: false,

      codInput: '',
      codInputLoading: false,
      cod: null,
      showPassReset: false,

      alertMessage: false,
      message: '',
      alertSuccess: false,

      email: null,
      password: null,

      errors: [],

      emailRules: [
        v => !!v || 'E-mail obligatoriu',
        v => /.+@.+/.test(v) || 'E-mailul trebuie sa fie valid',
      ],
    }
  },
  mounted() {
  },
  created() {
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.pass1 === this.pass2) || 'Cele 2 parole trebuie sa fie identice'
    },
  },
  methods: {
    async sendRecoveryEmail() {
      this.loading = true;

      if( this.$refs.recuperarePassForm.validate() ) {
        const response = await authApi.sendRecoveryEmail({email: this.email})
        if (response.status) {
          this.dialogCod = true
        }
      }

      this.loading = false;
    },

    async verificaCod() {
      if(this.codInput.length > 4) {
        this.codInputLoading = true
        this.cod = this.codInput
        this.codInput = ''

        const response = await authApi.verifyCode({email: this.email, cod: this.cod})
        if (response.status) {
          this.showPassReset = true
        }

        this.message = response.message
        this.alertMessage = true
        this.codInputLoading = false
      }
    },

    async resetPassword() {
      if(this.$refs.resetPassForm.validate()) {
        this.loadingPassReset = true

        const response = await authApi.changeNewPassword({email: this.email, cod: this.cod, password: this.pass1})
        if (response.status) {
          this.dialogCod = false
          this.alertSuccess = true
          this.email = null
        }

        this.loadingPassReset = false
      }
    },

    resetPassRecovery() {
      this.dialogCod = false
      this.showPassReset = false
      this.cod = null
    },
  }
}
</script>