<template>
  <div>
    <v-card class="mx-auto">
      <div class="pa-5">
        <h3>Date profil</h3>
        <v-divider class="my-5"></v-divider>

        <v-form ref="changeAccountDetailsForm" v-model="validDetailsForm">
          <v-row>
            <v-col cols="12" sm="6" xs="12">
              <v-text-field v-model="first_name" :counter="30" :rules="required" label="Nume *" required></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-text-field v-model="last_name" :counter="30" :rules="required" label="Prenume *" required></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-text-field disabled v-model="email" label="E-mail *" required></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-text-field v-model="phone" :rules="required" label="Telefon" required></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-autocomplete v-model="judet" :rules="required" label="Judet *" :items="items.judete" required></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-text-field v-model="cuim" :rules="[v => /^\d+$/.test(v) || 'Doar cifre']" label="CUIM *" required>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-select v-model="specialitate" :rules="required" :items="items.specialitati" label="Specialitatea 1 *" required></v-select>
            </v-col>

            <v-col sm="6" cols="12" xs="12">
              <v-select v-model="gradProfesional" :rules="required" :items="items.grade_didactice" label="Gradul profesional didactic *" required></v-select>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-select clearable v-model="specialitate2" :items="items.specialitati" label="Specialitatea 2" :rules="[bothOrNone]" :required="bothOrNone"></v-select>
            </v-col>

            <v-col sm="6" cols="12" xs="12">
              <v-select clearable v-model="gradProfesional2" :items="items.grade_didactice" label="Gradul profesional didactic" :rules="[bothOrNone]" :required="bothOrNone"></v-select>
            </v-col>

          </v-row>
        </v-form>

        <v-btn large :disabled="!validDetailsForm" color="primary" :loading="loadingDetailsForm" class="mr-4" @click="actualizeazaDate">
          ACTUALIZEAZA
        </v-btn>
      </div>
    </v-card>

    <v-divider class="my-5"></v-divider>

    <v-card class="mx-auto">
      <div class="pa-5">
        <h3>Schimba parola</h3>
        <v-divider class="my-5"></v-divider>
        <v-form ref="changePasswordForm" v-model="validChangePassword" >
          <v-row>
            <v-col cols="12" sm="6" xs="12">
              <v-text-field autocomplete="off" name="password" v-model="newPassword" label="Parola *" type="password"
                  :rules="[ v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special']"
                  required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-text-field v-model="newPasswordConfirm" label="Repeta parola *" type="password"
                  :rules="[v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Cel putin o litera mica, una mare, o cifra si un caracter special', passwordConfirmationRule]"
                  required
              ></v-text-field>
            </v-col>

          </v-row>
        </v-form>

        <v-btn large :disabled="!validChangePassword" color="primary" :loading="loadingChangePassword" class="mr-4" @click="schimbaParola">
          SCHIMBA
        </v-btn>
      </div>
    </v-card>

    <v-snackbar v-model="snackbar" color="success" :vertical="true" top right :timeout="3000">
      {{ snackbarMassage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { authApi } from "@auth/api";
import { AuthMutations } from "@auth/shared/auth.store";

export default {
  props: ["user", "isAuthenticated"],
  data() {
    return {
      snackbar: false,
      snackbarMassage: '',

      loadingDetailsForm: false,
      validDetailsForm: true,

      loadingChangePassword: false,
      validChangePassword: true,

      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      phone: this.user.phone,
      judet: this.user.judet,

      cuim: this.user.cuim,
      specialitate: this.user.specialitate,
      specialitate2: this.user.specialitate2,
      gradProfesional: this.user.gradProfesional,
      gradProfesional2: this.user.gradProfesional2,

      newPassword: null,
      newPasswordConfirm: null,

      items: {
        specialitati: [],
        grade_didactice: [],
        judete: []
      },

      required: [v => !!v || 'Camp obligatoriu'],

      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.newPassword === this.newPasswordConfirm) || 'Cele 2 parole trebuie sa fie identice'
    },
    bothOrNone() {
      return !((this.specialitate2 && !this.gradProfesional2) || (!this.specialitate2 && this.gradProfesional2))  || 'Camp obligatoriu'
    }
  },
  async mounted() {
    const specialitati = await authApi.getSpecialitati();
    if (specialitati.status) {
      this.items.specialitati = specialitati.data
    }

    const grade = await authApi.getGrade();
    if (grade.status) {
      this.items.grade_didactice = grade.data
    }

    const judete = await authApi.getJudete();
    if (judete.status) {
      this.items.judete = judete.data.map(item => {
        return {
          text: item['label'] + ' (' + item['code'] + ')',
          value: item['label']
        }
      });
    }
  },
  methods: {
    async actualizeazaDate() {
      if (this.$refs.changeAccountDetailsForm.validate()) {
        this.loadingDetailsForm = true;
        let data = {
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone,
          judet: this.judet,

          cuim: this.cuim,
          specialitate: this.specialitate,
          specialitate2: this.specialitate2,
          gradProfesional: this.gradProfesional,
          gradProfesional2: this.gradProfesional2
        }
        const response = await authApi.updateAuthUser(data);
        if (!response.errors.length) {
          this.snackbar = true;
          this.snackbarMassage = 'A fost modificat cu succes!';
          AuthMutations.SET_USER(response.data);
        }

        this.loadingDetailsForm = false;
      }
    },
    async schimbaParola() {
      this.loadingChangePassword = true;
      const data = {
        newPassword: this.newPassword
      }
      const response = await authApi.changePassword(data);
      if (!response.errors.length) {
        this.snackbar = true;
        this.snackbarMassage = 'Parola a fost modificata cu succes!';
      }
      this.loadingChangePassword = false;
    },
  }
}
</script>