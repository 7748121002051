<template>
  <div v-if="course">
    <v-row no-gutters>
      <v-col cols="12" sm="6" xs="12" class="text-center text-sm-left mb-2">
        <template v-if="!prevArticle">
          <v-btn outlined rounded small @click="goBackToCourse"><v-icon left dark>mdi-arrow-left</v-icon> Inapoi la curs</v-btn>
        </template>
        <template v-else>
          <v-btn outlined rounded small @click="goBackToArticle(prevArticle)"><v-icon left dark>mdi-arrow-left</v-icon> Precedentul articol</v-btn>
        </template>
      </v-col>
      <v-col v-if="nextArticle" cols="12" sm="6" xs="12" class="text-center text-sm-right">
        <v-btn outlined rounded small @click="goBackToArticle(nextArticle)">Urmatorul articol <v-icon right dark>mdi-arrow-right</v-icon></v-btn>
      </v-col>
    </v-row>

    <p class="font-weight-light mt-5 mb-0">
      CURS: {{ course.name }}
    </p>

    <p class="mb-5 mt-0 text--secondary">
      <v-icon small>mdi-clock-time-eight-outline</v-icon>
      <span class="pl-2" style="font-size: 11px;"> {{ course.start_date | changeDate }} | {{ course.end_date | changeDate }}</span>
    </p>

    <v-skeleton-loader v-if="loading" class="mx-auto" type="article"></v-skeleton-loader>
    <template v-else>
      <div v-if="article">
        <h1>{{ article.name }}</h1>
        <div v-html="article.description"></div>

        <v-divider class="my-5"></v-divider>

        <h2 class="mb-5">Chestionar</h2>
        <div v-for="quiz in quizzes" :key="quiz.id">
          <template v-if="quiz.isResolved">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="8" xs="12">
                    SCORUL DE <v-icon large :color="'purple darken-4'" v-text="`mdi-numeric-${quiz.correctUserAnswers}-box`"></v-icon> /
                    <v-icon large :color="'purple darken-4'" v-text="`mdi-numeric-${quiz.totalCorrectAnswers}-box`"></v-icon> LA ACEST CHESTIONAR
                  </v-col>
                  <v-col cols="12" sm="4" xs="12">
                    <v-btn color="primary" v-if="nextArticle" block @click="goBackToArticle(nextArticle)">Urmatorul articol <v-icon right dark>mdi-arrow-right</v-icon></v-btn>
                    <v-btn color="primary" v-else-if="!nextArticle" block @click="goBackToArticle(prevArticle)"><v-icon left dark>mdi-arrow-left</v-icon> Precedentul articol</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>

            <template v-if="quiz.questions.length">
              <div v-if="quiz.description" v-html="quiz.description"></div>
              <div :id="'question_' + question.id" v-for="(question, key) in quiz.questions" :key="question.id" style="position: relative" :class="'pb-3'">
                <v-overlay color="#ffffff" v-if="loadingSendQuiz || quiz.isDisabled" :absolute="true"></v-overlay>

                <v-card outlined :color="errors.includes(question.id) ? '#ffe4e4' : ''">
                <v-card-title>{{ key+1 }}. {{ question.name }}</v-card-title>

                  <v-list class="pa-0">
                    <template v-for="answer in question.answers">
                      <v-divider :key="'d' + answer.id"></v-divider>
                      <v-list-item :key="answer.id" @click="selectAnswer(question, answer)">
                        <template v-if="question.type == 'single'">
                          <v-list-item-icon>
                            <v-icon v-text="answer.userSelected ? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline'"></v-icon>
                          </v-list-item-icon>
                        </template>
                        <template v-else>
                          <v-list-item-icon>
                            <v-icon v-text="answer.userSelected ? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline'"></v-icon>
                          </v-list-item-icon>
                        </template>
                        <v-list-item-content class="flex-wrap">
                          <v-list-item-title class="text-wrap">{{ answer.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </div>
              <v-btn class="mt-4" :loading="loadingSendQuiz" color="primary" large dark @click="sendQuiz(quiz)">
                FINALIZEAZA CHESTIONAR
              </v-btn>
            </template>
          </template>
        </div>
      </div>
    </template>

    <v-dialog v-model="firstQuizModal" persistent width="900">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Start curs
        </v-card-title>

        <v-card-text class="pt-4 font-weight-bold">
          <v-alert outlined type="warning" prominent border="left">
            Va rugam sa confirmati daca datele de mai jos sunt corecte!<br>
            Certificarea se va genera cu aceste date:
          </v-alert>
          <br>
          <v-row>
            <v-col cols="12" sm="4" xs="12">
              <v-text-field readonly v-model="user.first_name" label="Nume"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" xs="12">
              <v-text-field readonly v-model="user.last_name" label="Prenume"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" xs="12">
              <v-text-field readonly v-model="user.email" label="Email"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" xs="12">
              <v-text-field readonly v-model="user.phone" label="Telefon"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" xs="12">
              <v-text-field readonly v-model="user.judet" label="Judet"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" xs="12">
              <v-text-field readonly v-model="user.cuim" label="CUIM"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-text-field readonly v-model="user.specialitate" label="Specialitate 1"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" xs="12">
              <v-text-field readonly v-model="user.gradProfesional" label="Grad profesional didactic"></v-text-field>
            </v-col>

            <template v-if="user.specialitate2">
              <v-col cols="12" sm="6" xs="12">
                <v-text-field readonly v-model="user.specialitate2" label="Specialitate 2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" xs="12">
                <v-text-field readonly v-model="user.gradProfesional2" label="Grad profesional didactic"></v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" sm="6" xs="12" class="text-center mt-5 mb-5">
              <v-btn :to="{ name: 'myAccount' }" target="_blank" @click="firstQuizModal=false">MODIFICA DATE CONT <v-icon right dark>mdi-account-cog-outline</v-icon></v-btn>
            </v-col>
            <v-col cols="12" sm="6" xs="12" class="text-center mt-5 mb-5">
              <v-btn color="success" @click="firstQuizModal=false">DATELE SUNT CORECTE <v-icon right dark>mdi-account-check-outline</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="courseCertification" v-if="courseCertification" persistent width="900">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
         Curs finalizat!
        </v-card-title>

        <v-card-text class="pt-4 font-weight-bold">
          <div class="text-center" style="font-sizefont-size: large;">

            <template v-if="courseCertification.passCourse === true">
              <h1 class="green--text"><v-icon color="success" large>mdi-check-bold</v-icon> Felicitari <v-icon>mdi-emoticon-cool-outline</v-icon></h1>
              <div>Felicitari {{ user.first_name }} {{ user.last_name }}</div>
              <v-chip color="green" text-color="white" class="ma-3">
                {{ courseCertification.percentage }} <v-icon>mdi-percent</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <h1 class="red--text"><v-icon color="red" large>mdi-close-circle</v-icon> Ne pare rau <v-icon>mdi-emoticon-cry-outline</v-icon></h1>
              <div>Nu ai promovat {{ user.first_name }} {{ user.last_name }}</div>
              <v-chip color="red" text-color="white" class="ma-3">
                {{ courseCertification['percentage'] }} <v-icon>mdi-percent</v-icon>
              </v-chip>
            </template>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" sm="6" xs="12" class="text-left text-sm-center mb-3">
              <v-btn v-if="courseCertification['passCourse'] === true" color="success" :to="{ name: 'myAccountComponent', params: { componentName: 'certifications' } }" @click="courseCertification=null">DESCARCA DIPLOMA<v-icon right dark>mdi-file-certificate-outline</v-icon></v-btn>
            </v-col>
            <v-col cols="12" sm="6" xs="12" class="text-right text-sm-center">
              <v-btn plain @click="courseCertification=null">INCHIDE</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { AuthGetters } from "@auth/shared/auth.store";
import { coursesApi } from "@courses/api";
import { CoursesGetters, CoursesActions } from "@courses/shared/course.store";

export default {
  props: ["articleId"],
  data() {
    return {
      loading: false,

      errors: [],
      article: null,
      quizzes: null,

      firstQuizModal: false,
      courseCertification: null,

      loadingSendQuiz: false,

    }
  },
  watch: {
    articleId() {
      this.getOne();
    },
  },
  filters: {
    changeDate: (date) => {
      if (!date) {
        return null;
      }
      return date;
    },
  },
  computed: {
    course: CoursesGetters.getCourse,

    user: AuthGetters.getUser,

    articleIdLocal: {
      get: function() {
        return this.courseId
      },
      set: function(value) {
        this.$emit('changeArticleId', value)
      }
    },
    nextArticle() {
      let articleId = null;
      let next = false;
      for (const article of this.course.articles) {
        if (next) {
          return article.id;
        }
        if (article.id == this.articleId) {
          next = true;
        }
      }
      return articleId;
    },
    prevArticle() {
      let articleId = null;
      for (const article of this.course.articles) {
        if (article.id == this.articleId) {
          return this.course.articles[(this.course.articles.indexOf(article) - 1)] ? this.course.articles[(this.course.articles.indexOf(article) - 1)].id : null;
        }
      }
      return articleId;
    },
  },
  mounted() {
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      const article = await coursesApi.getOneArticle(this.articleId);
      this.article = article.data;
      this.setUserAnswers(article.data.quizzes, article.userAnswers);
      this.loading = false;
    },
    setUserAnswers(quizzes, userAnswers) {
      // shuffle quiz quistion and answers
      let shuffleQuizzes = null;
      if (this.articleId == 6) {
        shuffleQuizzes = this.shuffleQuizAnswers(quizzes);
      } else {
        shuffleQuizzes = this.shuffleQuizQuestionAndAnswers(quizzes);
      }

      if (userAnswers && Object.keys(userAnswers).length) {
        this.quizzes = shuffleQuizzes.map(quiz => {
          if (userAnswers[quiz.id]) {
            return {
              isResolved: true,
              ...userAnswers[quiz.id]
            }
          } else {
            return quiz;
          }
        });
      } else {
        this.quizzes = shuffleQuizzes;
      }
    },
    goBackToCourse() {
      this.$router.push({ name: 'course', params: { courseId: this.course.id }});
    },

    goBackToArticle(articleId) {
      this.articleIdLocal = articleId;
      this.$router.push({ name: 'article', params: { courseId: this.course.id, articleId: articleId }});
    },

    async selectAnswer(question, answer) {
      if (question.type == 'single') {
        for await (const item of question.answers) {
          item.userSelected = false;
        }
        answer.userSelected = true;
      } else {
        answer.userSelected = answer.userSelected ? false : true
      }
      this.errors = this.errors.filter(id => id!=question.id);
    },

    async sendQuiz(quiz) {
      this.loadingSendQuiz = true;
      let errors = [];

      this.quizzes.forEach(quiz => {
        quiz.questions.forEach(question => {
          if (!question.answers.some(answer => answer.userSelected)) {
            errors.push(question.id);
          }
        })
      });
      this.errors = errors;

      // scroll
      if (errors[0]) {
        let requiredQuestion = this.$el.querySelector("#question_" + errors[0]);
        if (requiredQuestion) {
          setTimeout(function () {
            requiredQuestion.scrollIntoView({ behavior: 'smooth', block: 'end'});
          }, 300);
        }
      }

      if (!errors.length) {
        const userQuiz = await coursesApi.addOneUserQuizAnswers(this.article.id, quiz.id, quiz.questions);
        this.setUserAnswers(this.quizzes, userQuiz.data);
        CoursesActions.addUserQiuzAnswersToCouse(this.course.id, this.articleId, userQuiz);

        if (userQuiz['firstArticleToSolve']) {
          this.firstQuizModal = true;
        }

        if (userQuiz['certification']['status'] == 'finish') {
          this.courseCertification = userQuiz['certification'];
        }
      }
      this.loadingSendQuiz = false;
    },

    // Helpers
    shuffleQuizAnswers(quizzes) {
      let shuffleQuizzes = quizzes.map(quiz => {
        return {
          ...quiz,
          questions: quiz.questions.map(question => {
            return {
              ...question,
              answers: this.shuffleArray(question.answers)
            }
          })
        }
      });
      return shuffleQuizzes;
    },

    shuffleQuizQuestionAndAnswers(quizzes) {
      let shuffleQuizzes = quizzes.map(quiz => {
        return {
          ...quiz,
          questions: this.shuffleArray(
              quiz.questions.map(question => {
                return {
                  ...question,
                  answers: this.shuffleArray(question.answers)
                }
              })
          )
        }
      });
      return shuffleQuizzes;
    },

    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        // Generate random number
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
  }
}
</script>

<style>
.v-application--is-ltr .v-skeleton-loader__article .v-skeleton-loader__heading {
  margin: 0!important;
}
.v-skeleton-loader__article .v-skeleton-loader__paragraph {
  padding: 0!important;
  padding-top: 15px!important;
}
</style>