<template>

  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="orange lighten-2"
        >
          <v-btn
              icon
              dark
              @click="dialog=false;quiz=null;questions=null"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Intrebari chestionar <strong v-if="quiz">"{{quiz.name}}"</strong></v-toolbar-title>
        </v-toolbar>


        <v-container v-if="questions">

          <h2>Intrebari chestionar</h2>

          <v-divider class="mt-1 mb-7"></v-divider>

          <div v-if="questions.length">
            <v-card v-for="(question,i) in questions" :key="i" elevation="2" class="pa-3 mb-3">
              {{i+1}}. {{question.name}}

              <v-list>
                <v-list-item v-for="(answer,j) in question.answers" :key="j">
                  <v-list-item-icon>
                    <v-icon v-if="answer.isCorrect" color="green">mdi-check-circle-outline</v-icon>
                    <v-icon v-else color="orange">mdi-checkbox-blank-circle-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                     {{answer.name}}
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn color="primary" @click="editQuestion(question)">
                  Editeaza
                </v-btn>

                <v-btn color="red darken-1" class="white--text" @click="showDeleteQuestionModal(question.id)">
                  Sterge
                </v-btn>
              </v-card-actions>
            </v-card>

          </div>

          <div v-else>Nicio intrebare adaugata!</div>

          <v-btn ref="btnAdaugaIntrebare" color="green lighten-2" dark class="mt-3 mb-3" @click="addNewQuestion">
            Adauga o intrebare
            <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
          </v-btn>


          <v-dialog
              v-model="dialogAdd"
              scrollable
              persistent
              max-width="1000"
          >
            <v-card>
              <v-card elevation="5" class="pa-5">
                <h3 v-if="editedItem.id">Editeaza intrebarea</h3>
                <h3 v-else>Adauga o intrebare</h3>
              </v-card>

              <v-divider></v-divider>

              <v-card-text>
                <v-form
                    ref="formAddQuestion"
                    v-model="valid"
                    lazy-validation
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-textarea
                            v-model="editedItem.name"
                            label="Intrebare..."
                            auto-grow
                            rows="2"
                            autofocus
                            :rules="[
                              v => !!v || 'Introduceti intrebarea'
                          ]"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text v-if="editedItem.answers" ref="modalIntrebariScroll">
                    <v-row align="center" class="added-element" justify="center" v-for="answer in editedItem.answers" :key="answer.id">
                      <v-col cols="10">
                        <v-text-field
                            v-model="answer.name"
                            label="Raspuns"
                            auto-grow
                            :rules="[
                              v => !!v || 'Introduceti raspunsul'
                          ]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-checkbox v-model="answer.isCorrect" color="green" :rules="[atLeastOne]"></v-checkbox>
                            </span>
                          </template>
                          <span>Bifati pentru a seta acest raspuns ca fiind corect</span>
                        </v-tooltip>
                      </v-col>

                      <v-col cols="1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon class="mx-0" @click="deleteRaspuns(answer)" color="pink">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Sterge acest raspuns</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>

                </v-form>



              </v-card-text>

              <v-divider></v-divider>

              <v-card elevation="5" class="pl-4 pr-4">
                <v-card-actions>
                  <v-btn color="secondary" dark small class="mt-3 mb-3" @click="addNewAnswer">
                    Adauga un nou raspuns
                    <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                      text
                      @click="dialogAdd = false"
                  >
                    Anuleaza
                  </v-btn>
                  <v-btn
                      color="success"
                      dark
                      @click="saveQuestionAndAnswers"
                      :loading="loading"
                  >
                    Salveaza intrebarea
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-card>
          </v-dialog>


          <v-dialog
              v-model="dialogDelete"
              persistent
              max-width="450"
          >
            <v-card>
              <v-card-title>
                Sigur doriti stergerea acestei intrebari?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialogDelete=false;questionIdToDelete=null"
                >
                  Anuleaza
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="deleteQuestion(questionIdToDelete)"
                    :loading="loading"
                >
                  Da
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


        </v-container>

        <v-progress-linear v-else
                           indeterminate
                           color="indigo"
        ></v-progress-linear>

      </v-card>
    </v-dialog>
  </v-row>

</template>


<script>
import {articleApi} from "../api";

export default {
  name: "QuestionsModal",

  props: [],

  data () {
    return {
      dialog: false,
      dialogAdd: false,
      dialogDelete: false,
      valid: false,

      quiz: null,

      questions: null,

      editedItem: {},
      questionIdToDelete: null,
      loading: false
    }
  },

  methods: {
    openModal(quiz) {
      this.dialog = true
      this.quiz = quiz
      this.loadQuestions(quiz.id)
    },

    async loadQuestions(id) {
      const questions = await articleApi.getQuizQuestions(id);
      this.questions = questions.data.questions;
    },

    async saveQuestionAndAnswers() {
      this.loading = true
      let editedItem = this.editedItem;
      editedItem.quiz_id = this.quiz.id

      if( this.$refs.formAddQuestion.validate() ) {
        if( !editedItem.id ) { //add intrebare noua
          const response = await articleApi.addOneQuestion(this.editedItem);
          if (response.status) {
            this.questions.push(response.data);
            this.$refs.formAddQuestion.reset()
            this.dialogAdd = false
            this.$nextTick( () => this.$refs.btnAdaugaIntrebare.$el.scrollIntoView() )
          }
        }
        else { //editeaza intrebare
          const response = await articleApi.updateQuestion(editedItem.id, editedItem);
          if (response.status) {
            this.questions = this.questions.map(item => {
              if(item.id === editedItem.id) {
                return response.data
              }
              else {
                return item
              }
            })
            this.$refs.formAddQuestion.reset()
            this.dialogAdd = false
          }
        }
      }
      else {
        this.$nextTick(() => {
          if( this.$refs.formAddQuestion.validate() ) {
            let el = this.$refs.modalIntrebariScroll.querySelector(".v-messages.error--text:first-of-type");
            el.scrollIntoView()
          }
        });
      }
      this.loading = false
    },

    async deleteQuestion(id) {
      this.loading = true
      const response = await articleApi.deleteOneQuestion(id);
      if (response.status) {
        this.questions = this.questions.filter(item => item.id !== id)
        this.questionIdToDelete = null
        this.dialogDelete = false
      }
      this.loading = false
    },

    showDeleteQuestionModal(id) {
      this.questionIdToDelete = id
      this.dialogDelete = true
    },

    editQuestion(item) {
      this.editedItem = JSON.parse(JSON.stringify(item))
      this.dialogAdd = true
    },

    addNewQuestion() {
      this.editedItem = {
        answers: [
            {
              name: '',
              isCorrect: false,
            }
        ]
      }
      this.dialogAdd = true
    },

    addNewAnswer() {
      this.editedItem.answers.push({
        name: '',
        isCorrect: false,
      })
      this.$nextTick(() => {
        this.$refs.modalIntrebariScroll.querySelector('.added-element:last-child').scrollIntoView()
        this.$refs.modalIntrebariScroll.querySelector('.added-element:last-child input').focus()
      })
    },

    deleteRaspuns(answer) {
      let deleteIndex = this.editedItem.answers.indexOf(answer)
      this.editedItem.answers.splice(deleteIndex, 1)
    },

  },

  computed: {
    atLeastOne() {
      for (const item of this.editedItem.answers) {
        if(item.isCorrect) return true
      }
      return () => 'Alegeti un raspuns corect'
    }
  }
}
</script>