import Vue from "vue";
import { settingsApi } from "@admin/modules/settings/api";

const initialState = {
    stateStatus: false,

    loadingSettings: false,
    settings: [],
    setting: {}
}

const state = Vue.observable({ ...initialState });

const Getters = {
    getLoadingSettings: () => state.loadingSettings,
    getSettings: () => state.settings,
    getSetting: () => state.setting
}

const Mutations = {
    setLoadingSettings: (payload) => {
        state.loadingSettings = payload;
    },
    setSettings: (payload) => {
        state.settings = payload;
    },
    setSetting: (payload) => {
        state.setting = payload;
    },
    addSetting: (payload) => {
        state.settings.push(payload);
    },
    updateSetting: (id, payload) => {
        state.settings = state.settings.map(item => {
            if (item.id === id) {
                return payload;
            } else {
                return item;
            }
        });
    },
    deleteSetting: (id) => {
        state.settings = state.settings.filter(item => item.id !== id);
    },
}

const Actions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await Actions.fetchSettings();
        }
        state.stateStatus = true;
    },
    fetchSettings: async () => {
        Mutations.setLoadingSettings(true);
        const settings = await settingsApi.getAll();
        Mutations.setLoadingSettings(false);
        Mutations.setSettings(settings.data);
    },
    addSetting: async (data) => {
        const response = await settingsApi.addOne(data);
        if (response.status) {
            Mutations.addSetting(response.data);
        }
    },
    updateSetting: async (id, data) => {
        const response = await settingsApi.updateOne(id, data);
        if (response.status) {
            Mutations.updateSetting(id, response.data);
        }
    },
    deleteSetting: async (id) => {
        const response = await settingsApi.deleteOne(id);
        if (response.status) {
            Mutations.deleteSetting(id);
        }
    }
}

export const SettingsGetters = Getters;
export const SettingsMutations = Mutations;
export const SettingsActions = Actions;
