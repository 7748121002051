import Vue from "vue";
import { surveysApi } from "@admin/modules/surveys/api";

const initialState = {
    stateStatus: false,

    loading: false,
    collections: [],
    item: {}
}

const state = Vue.observable({ ...initialState });

const SurveysGetters = {
    getLoading: () => state.loading,
    getCollections: () => state.collections,
    getItem: () => state.item
}

const SurveysMutations = {
    setLoading: (payload) => {
        state.loading = payload;
    },
    setCollections: (payload) => {
        state.collections = payload;
    },
    setItem: (payload) => {
        state.item = payload;
    },
    addItem: (payload) => {
        state.collections.push(payload);
    },
    updateItem: (id, payload) => {
        state.collections = state.collections.map(item => {
            if (item.id === id) {
                return payload;
            } else {
                return item;
            }
        });
    },
    deleteItem: (id) => {
        state.collections = state.collections.filter(item => item.id !== id);
    },
}

const SurveysActions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await SurveysActions.fetchCollections();
        }
        state.stateStatus = true;
    },
    fetchCollections: async () => {
        SurveysMutations.setLoading(true);
        const collections = await surveysApi.getAll();
        SurveysMutations.setLoading(false);
        SurveysMutations.setCollections(collections.data);
    },
    addItem: async (data) => {
        const response = await surveysApi.addOne(data);
        if (response.status) {
            SurveysMutations.addItem(response.data);
        }
    },
    updateItem: async (id, data) => {
        const response = await surveysApi.updateOne(id, data);
        if (response.status) {
            SurveysMutations.updateItem(id, response.data);
        }
    },
    deleteItem: async (id) => {
        const response = await surveysApi.deleteOne(id);
        if (response.status) {
            SurveysMutations.deleteItem(id);
        }
    }
}

export { SurveysGetters };
export { SurveysMutations };
export { SurveysActions };
