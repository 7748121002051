import Vue from "vue";
import { certificationApi } from "@admin/modules/certifications/api";

const initialState = {
    stateStatus: false,

    loadingCertifications: false,
    certifications: [],
}

const state = Vue.observable({ ...initialState });

const Getters = {
    getLoadingCertifications: () => state.loadingCertifications,
    getCertifications: () => state.certifications,
}

const Mutations = {
    setLoadingCertifications: (payload) => {
        state.loadingCertifications = payload;
    },
    setCertifications: (payload) => {
        state.certifications = payload;
    },
}

const Actions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await Actions.fetchCertifications();
        }
        state.stateStatus = true;
    },
    fetchCertifications: async () => {
        Mutations.setLoadingCertifications(true);
        const certifications = await certificationApi.getAll();
        Mutations.setLoadingCertifications(false);
        Mutations.setCertifications(certifications.data);
    },
}

export const CertificationsGetters = Getters;
export const CertificationsMutations = Mutations;
export const CertificationsActions = Actions;
