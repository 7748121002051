<template>
  <div>
    <Article v-if="articleId" :articleId="articleId" v-on:changeArticleId="changeArticleId" />
    <Course v-else-if="courseId" :courseId="courseId" v-on:changeCourseId="changeCourseId" />
    <CoursesList v-else :courses="courses" :courseId="courseId" v-on:changeCourseId="changeCourseId" :loading="loadingCourses" />
  </div>
</template>
<script>
import { CoursesActions, CoursesGetters } from "@courses/shared/course.store";

import CoursesList from "./components/CoursesList";
import Course from "./components/Course";
import Article from "./components/Article";

export default {
  data() {
    return {
      courseId: this.$route.params.courseId ? this.$route.params.courseId : null,
      articleId: this.$route.params.articleId ? this.$route.params.articleId : null,
    }
  },
  computed: {
    loadingCourses: CoursesGetters.getLoadingCourses,
    courses: CoursesGetters.getCourses
  },
  watch: {
    '$route.params.courseId': async function (courseId) {
      this.courseId = courseId ?? null;
      if (this.courseId) {
        await CoursesActions.fetchCourse(this.courseId);
      }
    },
    '$route.params.articleId': function (articleId) {
      this.articleId = articleId ?? null;
    }
  },
  async mounted() {
    await CoursesActions.fetchCourses();
    if (this.courseId) {
      await CoursesActions.fetchCourse(this.courseId);
    }
  },
  methods: {
    changeCourseId(value) {
      this.courseId = value
    },
    changeArticleId(value) {
      this.articleId = value
    }
  },
  components: {
    CoursesList,
    Course,
    Article
  }
}
</script>