import AccountBuilder from "./views/AccountBuilder.vue";

const Routes = [
    {
        path: "/",
        name: "myAccount",
        component: AccountBuilder,
    },
    {
        path: "/:componentName",
        name: "myAccountComponent",
        component: AccountBuilder,
    }
]

export default Routes;