import CourseBuilder from "./views/CourseBuilder";

const Routes = [
    {
        path: "/",
        name: "courses",
        component: CourseBuilder,
    },
    {
        path: "/courses/:courseId",
        name: "course",
        component: CourseBuilder,
    },
    {
        path: "/courses/:courseId/article/:articleId",
        name: "article",
        component: CourseBuilder,
    }
]

export default Routes;