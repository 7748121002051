<template>

  <v-container v-if="items">

    <h2 class="mb-5">Articole</h2>


    <v-card>
      <v-data-table :headers="headers" no-data-text="Niciun curs adaugat" :items="items" mobile-breakpoint="800" class="elevation-0">
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" :to="{name: 'admin-article', params: {id: item.id}}" color="primary">
                  <v-icon>mdi-page-next-outline</v-icon>
                </v-btn>
              </template>
              <span>Detalii articol</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" :to="{name: 'admin-course-articles', params: {id: item.course_id}}" color="primary">
                  <v-icon>mdi-book-open-variant</v-icon>
                </v-btn>
              </template>
              <span>Vezi toate articolele cursului</span>
            </v-tooltip>

<!--            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" @click="deleteArticol(item.id)" color="pink">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Sterge articolul</span>
            </v-tooltip>-->

          </div>
        </template>
        <template v-slot:item.details="{ item }">
          <div class="text-truncate" style="width: 180px">
            {{item.Details}}
          </div>
        </template>
        <template v-slot:item.url="{ item }">
          <div class="text-truncate" style="width: 180px">
            <a :href="item.URL" target="_new">{{item.URL}}</a>
          </div>
        </template>
      </v-data-table>

    </v-card>


  </v-container>


  <v-progress-linear v-else
                     indeterminate
                     color="indigo"
  ></v-progress-linear>

</template>


<script>
import {articleApi} from "../api";

export default {
  name: "Articles",

  data() {
    return {
      items: null,

      headers: [
        { text: 'Titlu articol', value: 'name' },
        { text: 'Apartine cursului', value: 'course_name' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },

  mounted() {
    this.loadItems()
  },

  methods: {
    async loadItems() {
      const articles = await articleApi.getAll();
      this.items = articles.data;
    },
  }
}
</script>

<style scoped>

</style>