import VueRouter from "vue-router";
import { includePrefixedRoutes } from "./core/utils/include-routes";

import { authGuard } from "@auth/shared/auth.guard";

import authRoutes from "@auth/auth.routes";
import accountRoutes from "@account/account.routes";
import adminRoutes from "@admin/admin.routes";
import coursesRoutes from "@courses/courses.routes";

const routes = [
  ...includePrefixedRoutes("/auth", authRoutes),
  ...includePrefixedRoutes("/admin", adminRoutes),

  ...includePrefixedRoutes("/account", accountRoutes),
  ...includePrefixedRoutes("/", coursesRoutes),

  {
    path: '/:slug',
    name: 'page',
    component: () => import('@pages/views/PagesBuilder.vue')
  },
  {
    path: "/403-forbiden",
    name: "Forbiden",
    component: () => import('@core/views/Forbiden.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(authGuard);

export default router;
