<template>

  <v-container v-if="courseData">
    <h2 class="mb-5">
      Articole curs: {{courseData.name}}
      <v-btn
          rounded
          small
          color="primary"
          dark
          absolute
          top
          right
          :to="{name: 'admin-courses'}"
      >
        Vezi toate cursurile
      </v-btn>
    </h2>

    <v-card>
      <v-data-table :headers="headers" no-data-text="Niciun articol adaugat" :items="courseData['articles']" mobile-breakpoint="800" class="elevation-0">
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :to="{name: 'admin-article', params: {id: item.id}}" icon class="mx-0" color="primary">
                  <v-icon>mdi-page-next-outline</v-icon>
                </v-btn>
              </template>
              <span>Detalii articol</span>
            </v-tooltip>

<!--            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" @click="deleteItem(item)" color="pink">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Sterge acest articol</span>
            </v-tooltip>-->

          </div>
        </template>
        <template v-slot:item.details="{ item }">
          <div class="text-truncate" style="width: 180px">
            {{item.Details}}
          </div>
        </template>
        <template v-slot:item.url="{ item }">
          <div class="text-truncate" style="width: 180px">
            <a :href="item.URL" target="_new">{{item.URL}}</a>
          </div>
        </template>
      </v-data-table>

      <!-- this dialog is used for both create and update -->
      <v-dialog v-model="dialog" max-width="800px">

        <template v-slot:activator="{ on }">
          <div class="d-flex">
            <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
              Adauga articol pentru acest curs
              <v-icon small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </template>

        <v-card>
          <v-card-title>
            <span v-if="editedItem.id">Edit {{editedItem.id}}</span>
            <span v-else>Adaugare articol</span>
          </v-card-title>

          <v-form
              ref="formAddArticle"
              v-model="valid"
              lazy-validation
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field v-model="editedItem.name" label="Titlu articol" autofocus :rules="[ v => !!v || 'Introduceti numele articolului']"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModal()">Anuleaza</v-btn>
            <v-btn color="blue darken-1" text @click="saveItem()" :loading="loading">Salveaza</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </v-card>

  </v-container>


  <v-progress-linear v-else
                     indeterminate
                     color="indigo"
  ></v-progress-linear>

</template>


<script>
import {articleApi} from "../api";

export default {
  name: "CourseArticles",
  props: ["user", "isAuthenticated"],
  data() {
    return {
      valid: false,
      id: null,

      courseData: null,

      dialog: false,
      editedItem: null,
      loading: false,

      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Titlu articol', value: 'name' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.editedItem = {
      course_id: this.$route.params.id
    }
    this.loadData()
  },
  methods: {
    async loadData() {
      const courseArticles = await articleApi.getCourseArticles(this.id);
      this.courseData = courseArticles.data;
      //console.log(courseArticles.data)
    },

    async saveItem() {
      this.loading = true
      let editedItem = this.editedItem;

      if( this.$refs.formAddArticle.validate() ) {
        if (!editedItem.id) {
          const response = await articleApi.addOneArticle(editedItem);
          if (response.status) {
            this.courseData['articles'].push(response.data);
          }
        }
        this.editedItem = {
          course_id: this.id
        };
        this.dialog = false
      }
      this.loading = false
    },

    async deleteItem(item) {
      let id = item.id
      const response = await articleApi.deleteOneArticle(item.id);
      if (response.status) {
        this.courseData['articles'] = this.courseData['articles'].filter(item => item.id != id);
      }
    },

    closeModal() {
      this.dialog = false
    }
  }
}
</script>