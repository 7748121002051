import Specializari from "./views/Specializari";

const Routes = [
    {
        path: "/specializari",
        name: "admin-specializari",
        component: Specializari,
        meta: {
            title: 'Specializari',
        }
    },
]

export default Routes;