<template>
  <div>
  <v-container v-if="settings">
    <h2 class="mb-5">Setari aplicatie</h2>
    <v-card>
      <v-data-table :headers="headers" no-data-text="Niciun utilizator activ" :items="settings" mobile-breakpoint="800" class="elevation-0">
        <template #item.full_name="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="editSetting(item)" icon class="mx-0" color="primary">
                  <v-icon>mdi-clipboard-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editeaza</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :loading="item.id === deletePreloader" v-bind="attrs" v-on="on" @click="deleteSetting(item)" icon class="mx-0" color="pink">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Sterge</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-btn color="green lighten-2" @click="addSetting" dark class="mt-3 mb-3">
      Setare noua
      <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
    </v-btn>
  </v-container>

  <v-progress-linear v-else indeterminate color="indigo"></v-progress-linear>

  <v-dialog v-model="dialog" persistent max-width="900">
      <v-card>
        <v-card-title>
          <span class="text-h5" v-text="setting.id ? 'Editeaza' : 'Setare noua'"></span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="setting.key" :rules="required" label="Key" required></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="setting.value" :rules="required" label="Value" required></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-row no-gutters>
            <v-col cols="6" md="6">
              <v-btn :disabled="!valid" :loading="savePreloader" color="primary" @click="save">{{ setting.id ? 'SALVEAZA' : 'ADAUGA' }}</v-btn>
            </v-col>
            <v-col cols="6" md="6" class="text-right">
              <v-btn text @click="closeModal">Inchide</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { SettingsActions, SettingsMutations, SettingsGetters } from "@admin/modules/settings/shared/settings.store";

export default {
  name: "Settings",

  data() {
    return {
      dialog: false,
      savePreloader: false,
      deletePreloader: false,
      valid: false,

      required: [
        v => !!v || 'Camp obligatoriu'
      ],

      headers: [
        { text: 'Key', value: 'key' },
        { text: 'Valoare', value: 'value' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
    }
  },
  async mounted() {
    await SettingsActions.init();
  },
  computed: {
    settings: SettingsGetters.getSettings,
    setting: SettingsGetters.getSetting
  },
  methods: {
    editSetting(setting) {
      this.dialog = true;
      SettingsMutations.setSetting({ ...setting });
    },
    addSetting() {
      SettingsMutations.setSetting({});
      this.dialog = true;
    },
    async save() {
      this.savePreloader = true;
      const data = {
        key: this.setting.key,
        value: this.setting.value
      }
      if (this.setting.id) {
        await SettingsActions.updateSetting(this.setting.id, data);
      } else {
        await SettingsActions.addSetting(data);
      }
      this.savePreloader = false;
      this.closeModal();
    },
    async deleteSetting(setting) {
      this.deletePreloader = setting.id;
      if (confirm("Sigur doriți să ștergeți această setare?")) {
        await SettingsActions.deleteSetting(setting.id);
      }
      this.deletePreloader = false;
    },
    closeModal() {
      this.dialog = false;
      SettingsMutations.setSetting({});
    }
  }
}
</script>