import axios from "axios";

const getAll = async () => {
    try {
        const response = await axios.get("/api/admin/articles");
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getOne = async (id) => {
    try {
        const response = await axios.get("/api/admin/article/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const updateOne = async (id, data) => {
    try {
        const response = await axios.put("/api/admin/article/" + id, data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const addOneArticle = async (data) => {
    try {
        const response = await axios.post("/api/admin/article", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const deleteOneArticle = async (id) => {
    try {
        const response = await axios.delete("/api/admin/article/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getCourseArticles = async (id) => {
    try {
        const response = await axios.get("/api/admin/course-articles/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getArticleQuizzes = async (id) => {
    try {
        const response = await axios.get("/api/admin/article-quizzes/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const addOneQuiz = async (data) => {
    try {
        const response = await axios.post("/api/admin/quiz", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getQuizQuestions = async (id) => {
    try {
        const response = await axios.get("/api/admin/quiz/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const addOneQuestion = async (data) => {
    try {
        const response = await axios.post("/api/admin/question", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const updateQuestion = async (id, data) => {
    try {
        const response = await axios.put("/api/admin/question/" + id, data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const deleteOneQuestion = async (id) => {
    try {
        const response = await axios.delete("/api/admin/question/" + id);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export default {
    getAll,
    getOne,
    updateOne,
    addOneArticle,
    deleteOneArticle,

    getCourseArticles,

    getArticleQuizzes,
    addOneQuiz,

    getQuizQuestions,
    addOneQuestion,
    updateQuestion,
    deleteOneQuestion
}