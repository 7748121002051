import Login from "./views/Login.vue";
import Logout from "./views/Logout.vue";
import ForgotPassword from "./views/ForgotPassword";

const Routes = [
  {
    path: "/login",
    name: "user-login",
    component: Login,
    meta: {
      isPublicPage: true,
      redirectToAccountPage: true
    }
  },
  {
    path: "/logout",
    name: "user-logout",
    component: Logout
  },
  {
    path: "/recuperare-parola",
    name: "recuperare-parola",
    component: ForgotPassword,
    meta: {
      isPublicPage: true
    }
  }
]

export default Routes;