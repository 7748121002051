<template>
  <div v-if="isAuthenticated">
    <v-app-bar app fixed dark color="#052049">

      <router-link :to="{ name: 'courses' }" v-slot="{ navigate }" custom class="d-flex align-center">
        <a @click="navigate" role="link"><img :src="logo" width="125"/></a>
      </router-link>

      <!--<v-btn class="ml-5" text :to="{ name: 'page', params: { slug: 'contact' } }" active-class="active" exact>Contact</v-btn>-->

      <v-spacer></v-spacer>

      <v-menu open-on-hover offset-y left transition="slide-y-transition" min-width="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed color="#052049" v-bind="attrs" v-on="on">
            <v-icon>mdi-account-circle-outline</v-icon>
            <span class="pl-1">{{ user.first_name + ' ' + user.last_name | shortHand }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item :to="{ name: 'myAccount' }" exact>
            <v-list-item-title>Profilul meu</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item :to="{ name: 'myAccountComponent', params: { componentName: 'certifications' }}" exact>
            <v-list-item-title>Diplomele mele</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item :to="{ name: 'user-logout' }">
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import logo from "@/assets/logo-emcb-white.svg";

export default {
  props: ["user", "isAuthenticated"],
  data() {
    return {
      logo: logo,
    }
  },
  computed: {
  },
  filters: {
    uppercase: function(value) {
      if (!value) return '';
      return value.toUpperCase();
    },
    shortHand(value) {
      if (!value) return '';
      return value.split(' ').map(x => x[0].toUpperCase()).join('');
    }
  },

  created() {},
  mounted() {},
  methods: {

  },
  components: {

  },
}
</script>

<style scoped>

</style>