<template>
  <div>
    <div v-if="addSingleFile">
      <v-btn outlined @click="openFileManager = true" class="mb-6">
        <v-icon small class="mr-3">mdi-image-plus</v-icon>
        {{ btnLabel ? btnLabel : 'Adauga imagine' }}
      </v-btn>
    </div>

    <v-row v-if="selectedFiles.length">
      <v-col v-for="file in selectedFiles" :key="file" class="d-flex child-flex" cols="4">
        <v-card>
          <v-btn color="red" fab dark x-small absolute top right @click="removeFile(file)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>

          <v-card-text align="center" justify="center">
            <v-img contain max-height="150" :lazy-src="APP_SERVER + file" :src="APP_SERVER + file">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog fullscreen v-model="openFileManager" transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="openFileManager = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ filemanagerTitle }}</v-toolbar-title>
        </v-toolbar>
        <div style="display: flex; width: 100%; height: 100%; flex-direction: column; ">
          <iframe id="filemagerIframe" :src="filemanagetPath + '?conf=' + filemanagerConf + filemanagerModule" frameborder="0" style="flex-grow: 1; border: none; margin: 0; padding: 0;" height="100%" width="100%"></iframe>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="messageDialog" max-width="450">
      <v-card>
        <v-card-title>
          <span>{{ messageDialogText }}</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" @click="messageDialog=false;">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import { EventBus } from '@/main';

export default {
  props: {
    'value': Array,
    'conf': String,
    'title': String,
    'type': String,
    'btnLabel': String,
    'single': [Boolean, String],
    'allowExtension': Array
  },
  data() {
    return {
      APP_SERVER: process.env.VUE_APP_SERVER,

      openFileManager: false,

      messageDialog: false,
      messageDialogText: '',

      filemanagerTitle: this.title ?? 'File manager',
      filemanagerConf: this.conf ?? 'default',
      filemanagerModule: this.type == 'select' ? '&module=filemanager' : '',
    }
  },
  computed: {
    addSingleFile() {
      if (this.single === 'true' || this.single) {
        if (this.selectedFiles.length) {
          return false;
        }
      }
      return true;
    },
    filemanagetPath() {
      return this.APP_SERVER + '/manager/';
    },
    selectedFiles: {
      get: function() {
        return this.value
      },
      set: function(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    'openFileManager': async function (value) {
      if (value) {
        setTimeout(function() {
          document.getElementById('filemagerIframe').height = window.innerHeight - 65;
        }, 300);
      }
    },
    'window.filemanagerSelected': function(value) {
      console.log(value);
    }
  },
  mounted() {
    let el = this;
    EventBus.$on('filemanagerSelected', function(selectedFile) {
      el.selectFile(selectedFile);
    })
  },
  methods: {
    removeFile(file) {
      let elemIndex = this.selectedFiles.indexOf(file);
      this.selectedFiles = this.selectedFiles.filter((value, index) => index !== elemIndex)
    },
    selectFile(file) {
      if (this.type === 'select') {
        if (this.checkExtension(file)) {
          if (!this.selectedFiles.includes(file)) {
            this.selectedFiles.push(file);
          }
          this.openFileManager = false;
        } else {
          this.messageDialogText = 'Doar fisiere de tipul: ' + this.allowExtension.join(', ');
          this.messageDialog = true;
        }
      }
    },
    checkExtension(filename){
      if (this.allowExtension && this.allowExtension.length) {
        let fileExt = filename.split('.').pop();
        return this.allowExtension.includes(fileExt.toLowerCase());
      }
      return true;
    }
  }
}
</script>