import Pages from "./views/Pages";

const Routes = [
    {
        path: "/pages",
        name: "admin-pages",
        component: Pages,
        meta: {
            title: 'Pagini',
        }
    },
]

export default Routes;