var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-container',[_c('h2',{staticClass:"mb-5"},[_vm._v("Articole")]),_c('v-card',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"no-data-text":"Niciun curs adaugat","items":_vm.items,"mobile-breakpoint":"800"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0",attrs:{"icon":"","to":{name: 'admin-article', params: {id: item.id}},"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-page-next-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalii articol")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0",attrs:{"icon":"","to":{name: 'admin-course-articles', params: {id: item.course_id}},"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-book-open-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Vezi toate articolele cursului")])])],1)]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(item.Details)+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"180px"}},[_c('a',{attrs:{"href":item.URL,"target":"_new"}},[_vm._v(_vm._s(item.URL))])])]}}],null,false,2059019580)})],1)],1):_c('v-progress-linear',{attrs:{"indeterminate":"","color":"indigo"}})}
var staticRenderFns = []

export { render, staticRenderFns }