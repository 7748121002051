<template>
  <div>
    <editor
        :api-key="apiKey"
        :init="config"
        v-model="valueLocal"
    />
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  props: ['value', 'conf', 'title'],
  data() {
    return {
      apiKey: '4nrngucnj9xfmnixfzagc60getnws4ocooak7ot3yzp28thd',

      config: {
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount image'
        ],
        image_advtab: true,

        toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | code | image',

        filemanager_external_path: process.env.VUE_APP_SERVER + '/manager/',
        filemanager_title: this.title ?? 'File manager',
        filemanager_conf: this.conf ?? 'default',

        external_plugins: { 'filemanager' : '/filemanager/tinyMCE-filemanager.js' }
      }
    }
  },
  computed: {
    valueLocal: {
      get: function() {
        return this.value
      },
      set: function(value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    Editor
  },
  mounted() {

  },
}
</script>