<template>
  <v-container v-if="course && coordonatori && organizatori">
    <h2 class="mb-5">
      Nume curs: {{ course['name'] }}
      <v-btn rounded small color="primary" dark absolute top right :to="{name: 'admin-courses'}">
        <v-icon left dark>mdi-arrow-left</v-icon> Inapoi la cursuri
      </v-btn>
    </h2>

    <v-alert v-model="alertSuccess" dismissible color="success" border="left" elevation="2" colored-border icon="mdi-check-bold">
      <strong>Cursul a fost actualizat cu success!</strong>
    </v-alert>

    <v-alert :value="alertFail" outlined type="warning" text transition="scale-transition">
      <v-row align="center">
        <v-col class="grow">
          Actualizare esuata!
        </v-col>
        <v-col class="shrink">
          <v-btn @click="alertFail=false">OK</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-form ref="form" v-model="valid" lazy-validatio>

        <v-row>
          <v-col>
            <v-text-field v-model="course['name']" :rules="[v => !!v || 'Numele cursului este obligatoriu']" label="Nume curs" required></v-text-field>
          </v-col>
        </v-row>

        <Filemanager v-model="course.link_images" :allowExtension="['jpg', 'jpeg', 'png']" type="select" single="true" btnLabel="Imagine curs" />

        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field v-model="course['short_description']" label="Descriere pe scurt a cursului" counter required
                :rules="[
                          v => !!v || 'Descrierea pe scurt este obligatorie',
                          v => (v && v.length<=256) || 'Maxim 256 caractere'
                      ]"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-alert v-if="(requiredOrganizatori || requiredCoordonatori)" dense outlined type="error" class="mt-4">
          Asociati minim un <strong v-if="requiredOrganizatori">Organizator</strong> <template v-if="requiredOrganizatori">si</template> <strong v-if="requiredCoordonatori">Coordonator</strong> pe acest curs.
        </v-alert>

        <!-- Organizatori  -->
        <v-row>
          <v-col md="6" sm="12">
            <v-card :class="'mx-auto' + (requiredOrganizatori ? ' box-error' : '')" outlined>
              <v-card-text>
                <v-form v-model="validOrganizator">
                  <v-row>
                    <v-col md="7">
                      <v-autocomplete class="ma-0 pa-0" :rules="required" v-model="organizator.id" :items="serializeOrganizatori" no-data-text="Niciun organizator adaugat" placeholder="Organizatori" required>
                        <template v-slot:item="{ item }">
                          {{ item.text }} ({{ item.description }})
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3">
                      <v-select required :rules="required" v-model="organizator.type" class="ma-0 pa-0" :items="COtypes.filter(item => !this.course.organizatori.map(item => item.type).includes(item))" label="Tipul"></v-select>
                    </v-col>
                    <v-col class="text-center" md="1">
                      <v-btn :disabled="!validOrganizator" @click="selectOrganizator" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <v-divider></v-divider>

                <div class="mt-3" v-if="course['organizatori'].length">
                  <v-chip class="ma-2" :color="typeColor(organizatorAsociat.type)" text-color="white" close @click:close="removeOrganizator(organizatorAsociat.id)" v-for="organizatorAsociat in course['organizatori']" :key="organizatorAsociat.id">
                    {{ organizatorAsociat.name }} - {{ organizatorAsociat.type }} ({{ organizatorAsociat.description }})
                  </v-chip>
                </div>
                <div class="mt-3" v-else>Niciun organizator asociat.</div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Coordonator  -->
          <v-col md="6" sm="12">
            <v-card :class="'mx-auto' + (requiredCoordonatori ? ' box-error' : '')" outlined>
              <v-card-text>
                <v-form v-model="validCoordonator">
                  <v-row>
                    <v-col md="7">
                      <v-autocomplete :rules="required" class="ma-0 pa-0" v-model="coordonator.id" :items="serializeCoordonatori" no-data-text="Niciun coordonator adaugat" placeholder="Coordonatori" required>
                        <template v-slot:item="{ item }">
                          {{ item.text }} ({{ item.description }})
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3">
                      <v-select :rules="required" v-model="coordonator.type" class="ma-0 pa-0" :items="COtypes.filter(item => !this.course.coordonatori.map(item => item.type).includes(item))" label="Tipul" required></v-select>
                    </v-col>
                    <v-col class="text-center" md="1">
                      <v-btn :disabled="!validCoordonator" @click="selectCoordonatori" color="primary"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <v-divider></v-divider>

                <div class="mt-3" v-if="course['coordonatori'].length">
                  <v-chip elevation="10" class="ma-2" :color="typeColor(coordonatorAsociat.type)" text-color="white" close @click:close="removeCoordonator(coordonatorAsociat.id)" v-for="coordonatorAsociat in course['coordonatori']" :key="coordonatorAsociat.id">
                    {{ coordonatorAsociat.name }} - {{ coordonatorAsociat.type }} ({{ coordonatorAsociat.description }})
                  </v-chip>
                </div>
                <div class="mt-3" v-else>Niciun coordonator asociat.</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-divider class="mt-10 mb-10"></v-divider>

        <v-row>
          <v-col md="3">
            <v-text-field v-model="course['decizie_creditare']" label="Decizie creditare"></v-text-field>
          </v-col>

          <v-col md="3">
            <v-text-field v-model="course['credite_emc']" label="Credite EMC" :rules="[v => (!v || /^\d+$/.test(v)) || 'Doar cifre']" required></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="course['start_date']" label="Data start curs" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="course['start_date']" @input="menu = false" locale="ro-ro"></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="course['end_date']" label="Data finalizare curs" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="course['end_date']"  @input="menu2 = false" locale="ro-ro"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

      <Editor v-model="course['description']"  />

      <div class="mt-5">
        <v-btn color="red" outlined v-if="course['is_deleteable']" dark @click="deleteCourseModal" large class="float-left">
          Sterge curs
        </v-btn>

        <v-btn color="green" dark @click="save()" large :loading="loading" class="float-right">
          Salveaza modificari
        </v-btn>

        <div style="clear:both"></div>
      </div>
    </v-form>

    <v-dialog
        v-model="modalDelete"
        persistent
        max-width="450"
    >
      <v-card>
        <v-card-title class="text-break">
          Sigur doriti stergerea acestui curs? Articolele atasate impreuna cu chestionarele acestora vor fi sterse!
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="modalDelete=false"
          >
            Anuleaza
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="deleteCourse"
              :loading="loadingDelete"
          >
            Da
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
  <v-progress-linear v-else indeterminate  color="indigo"></v-progress-linear>
</template>

<script>
import { courseApi } from "@admin/modules/courses/api";
import { coordonatoriApi } from "../../coordonatori/api";
import { organizatoriApi } from "../../organizatori/api";
import { CoursesActions } from "@admin/modules/courses/shared/course.store";

import Editor from '@core/views/TinyMCE-Editor';
import Filemanager from '@core/views/Filemanager';

import goTo from "vuetify/lib/services/goto";

export default {
  props: ["user", "isAuthenticated"],

  components: {
    Editor,
    Filemanager
  },
  data() {
    return {
      id: this.$route.params.id,
      course: null,

      modalDelete: false,

      uploadsDir: process.env.VUE_APP_UPLOADSDIR,
      COtypes: ['Principal', 'Unic', 'Asociat', 'Teritorial'],

      validOrganizator: false,
      requiredOrganizatori: false,
      organizator: {
        id: null,
        type: null
      },

      validCoordonator: false,
      requiredCoordonatori: false,
      coordonator: {
        id: null,
        type: null
      },

      required: [v => !!v || 'Camp obligatoriu'],

      coordonatori: [],
      organizatori: [],

      imageToUpload: null,

      alertSuccess: false,
      alertFail: false,

      valid: false,

      menu: false,
      menu2: false,
      loadingC: false,
      loadingO: false,
      loading: false,
      loadingDelete: false,
    }
  },

  mounted() {
    this.loadCourseData();
  },

  computed: {
    serializeCoordonatori() {
      return this.coordonatori
          .filter(item => !this.course.coordonatori.map(item => item.id).includes(item.id))
          .map((item) => {
        return {
          text: item.name,
          type: item.type,
          value: item.id,
          description: item.description
        }
      })
    },
    serializeOrganizatori() {
      return this.organizatori
          .filter(item => !this.course.organizatori.map(item => item.id).includes(item.id))
          .map((item) => {
        return {
          text: item.name,
          type: item.type,
          value: item.id,
          description: item.description
        }
      })
    }
  },

  methods: {
    async loadCourseData() {
      const course = await courseApi.getOne(this.id);
      this.course = course.data;

      const coordonatori = await coordonatoriApi.getAll();
      this.coordonatori = coordonatori.data;

      const organizatori = await organizatoriApi.getAll();
      this.organizatori = organizatori.data;
    },

    async save() {
      this.requiredOrganizatori = !this.course.organizatori.length;
      this.requiredCoordonatori = !this.course.coordonatori.length;

      if (!this.$refs.form.validate()) {
        goTo(60);
      }

      if(this.$refs.form.validate()) {
        if (this.requiredOrganizatori || this.requiredCoordonatori) {
          goTo(280);
          return;
        }
        this.loading = true

        const response = await courseApi.updateOne(this.id, { ...this.course });
        if (response.status) {
          this.course = response.data
          this.alertSuccess = true
          this.autoCloseAlert();
          goTo(0)
        } else {
          this.alertFail = true
        }
      }

      this.loading = false
    },

    selectOrganizator() {
      this.course.organizatori.push({
        ...this.organizatori.find(item => item.id === this.organizator.id),
        type: this.organizator.type
      });
      this.organizator = { id: null, type: null };
      this.requiredOrganizatori = !this.course.organizatori.length;
    },
    removeOrganizator(id) {
      this.course.organizatori = this.course.organizatori.filter(item => item.id !== id);
      this.requiredOrganizatori = !this.course.organizatori.length;
    },

    selectCoordonatori() {
      this.course.coordonatori.push({
        ...this.coordonatori.find(item => item.id == this.coordonator.id),
        type: this.coordonator.type
      });
      this.coordonator = { id: null, type: null };
      this.requiredCoordonatori = !this.course.coordonatori.length;
    },
    removeCoordonator(id) {
      this.course.coordonatori = this.course.coordonatori.filter(item => item.id !== id);
      this.requiredCoordonatori = !this.course.coordonatori.length;
    },

    typeColor(type) {
      switch (type) {
        case 'Principal':
          return 'green'
        case 'Unic':
          return 'blue'
        case 'Asociat':
          return 'brown lighten-3'
        case 'Teritorial':
          return 'teal lighten-2'
      }
    },

    autoCloseAlert() {
      setTimeout(function () {
        this.alertSuccess = false
      }.bind(this), 5000)
    },

    deleteCourseModal() {
      this.modalDelete = true
    },

    async deleteCourse() {
      this.loadingDelete = true
      const response = await CoursesActions.deteleOneCourse(this.id);
      if (response.status) {
        await this.$router.push({name: 'admin-courses'})
      }
      this.loadingDelete = false
    },
  }
}
</script>
<style scoped>

</style>