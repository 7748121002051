import axios from "axios";

const getAll = async () => {
    try {
        const response = await axios.get("/api/pages");
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

const getOne = async (slug) => {
    try {
        const response = await axios.get(`/api/pages/${slug}`);
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

export default {
    getAll,
    getOne,
}