import axios from "axios";

const login = async ({ email, password }) => {
    try {
        const response = await axios.post("/auth/login", {
            email,
            password
        });
        return response
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const registerUser = async (data) => {
    try {
        const response = await axios.post("/auth/register", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const logout = async () => {
    try {
        return await axios.get("/auth/logout")
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const loadUser = async () => {
    try {
        return await axios.get("/api/user")
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const updateAuthUser = async (data) => {
    try {
        const response = await axios.put("/api/user", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const changePassword = async (data) => {
    try {
        const response = await axios.put("/api/user/updatePassword", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const sendRecoveryEmail = async (data) => {
    try {
        const response = await axios.post("/auth/forgot-password", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const verifyCode = async (data) => {
    try {
        const response = await axios.post("/auth/verify-code", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const changeNewPassword = async (data) => {
    try {
        const response = await axios.post("/auth/change-new-password", data);
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getSpecialitati = async () => {
    try {
        const response = await axios.get("/api/specializari")
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getGrade = async () => {
    try {
        const response = await axios.get("/api/grade")
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

const getJudete = async () => {
    try {
        const response = await axios.get("/api/judete")
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export default {
    login,
    logout,
    loadUser,
    registerUser,
    updateAuthUser,
    changePassword,
    sendRecoveryEmail,
    verifyCode,
    changeNewPassword,

    getSpecialitati,
    getGrade,
    getJudete
}