<template>
  <v-app>
    <v-progress-circular v-if="preloader && isAuthenticated" class="preloader" :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    <template v-else>
      <template v-if="!adminView">
        <Navbar :user="user" :isAuthenticated="isAuthenticated" />
        <v-container style="height: 100%">
          <v-main>
            <router-view :user="user" :isAuthenticated="isAuthenticated" />
          </v-main>
        </v-container>
        <v-footer>
          <v-row no-gutters>
            <v-col cols="12" sm="6" xs="12" class="text-sm-left text-center">
              <span class="text--disabled" style="font-size: 13px">Copyright {{ new Date().getFullYear() }} EMCB</span>
              <template v-if="isAuthenticated">
                <span class="ma-2">|</span> <v-btn text x-small :to="{ name: 'page', params: { slug: 'contact' }}">Contact</v-btn> <span class="ma-2">|</span> <v-btn text x-small :to="{ name: 'page', params: { slug: 'termeni-si-conditii' }}">Termeni si conditii</v-btn>
              </template>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-right text-center" xs="12">
              <a href="https://play-solutions.ro/" class="text--disabled developedBy" target="_blank">Developed by Play Solutions.</a>
            </v-col>
          </v-row>
        </v-footer>
      </template>

      <template v-else>

        <v-app>
          <NavbarAdmin :user="user" :isAuthenticated="isAuthenticated" />
          <!-- Sizes your content based upon application components -->
          <v-main>
            <!-- Provides the application the proper gutter -->
            <v-container fluid>
              <!-- If using vue-router -->
              <router-view :user="user" :isAuthenticated="isAuthenticated" />
            </v-container>
          </v-main>
        </v-app>

        <v-footer app>
          <v-row no-gutters>
            <v-col cols="12" sm="6" xs="12" class="text-sm-left text-center">
              <span class="text--disabled" style="font-size: 13px">Copyright {{ new Date().getFullYear() }} EMCB</span>
            </v-col>
            <v-col cols="12" sm="6" class="text-sm-right text-center" xs="12">
              <a href="https://play-solutions.ro/" class="text--disabled developedBy" target="_blank">Developed by Play Solutions.</a>
            </v-col>
          </v-row>
        </v-footer>
      </template>
    </template>
  </v-app>
</template>

<script>
import Navbar from "./apps/common/header/Navbar";
import NavbarAdmin from "./apps/admin/modules/common/header/Navbar";

import { AuthGetters, AuthActions } from "@auth/shared/auth.store";

export default {
  name: 'App',
  data() {
    return {
      preloader: true
    }
  },
  watch: {
    async user() {
      let forbiden = await AuthActions.isForbiden('ROLE_ADMIN');
      if (forbiden && this.$route.path != '/403-forbiden') {
        window.location = "/403-forbiden";
        return;
      }
      this.preloader = false;
    }
  },
  mounted() {

  },
  computed: {
    adminView: AuthGetters.isAdmin,
    user: AuthGetters.getUser,
    isAuthenticated: AuthGetters.isAuthenticated,
  },
  methods: {
    async forbiden(user) {
      for await (const role of user.roles) {
        if (role === 'ROLE_ADMIN') {
          return false;
        }
      }
      return true;
    },
  },
  components: {
    Navbar,
    NavbarAdmin
  }
};
</script>
<style>
* {
  word-break: normal!important;
}
body {
  margin: 0;
}
.v-main img {
  max-width: 100%;
  height: auto;
}
.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.developedBy {
  font-size: 13px;
  text-decoration: none;
}
</style>
