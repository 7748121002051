<template>
  <div v-if="course">
    <div>
      <v-btn outlined rounded small @click="goBackToCourses"><v-icon left dark>mdi-arrow-left</v-icon> Inapoi</v-btn>
    </div>

    <div>
      <h1>{{ course.name }}</h1>
      <div v-html="course.description"></div>

      <v-divider class="my-5"></v-divider>

      <h3>{{ course.articles.length }} ARTICOLE</h3>
      <v-card class="mb-4" v-for="(article, index) in course.articles" :key="article.id" @click="goToArticle(article)">
        <v-layout>
          <v-flex xs1>
            <div class="article-count">
              <span>{{ index+1 }}.</span>
            </div>
          </v-flex>
          <v-flex xs9>
            <v-card-title>{{ article.name }}</v-card-title>
            <v-card-text>
              <div>{{ article.short_description }}</div>
            </v-card-text>
          </v-flex>
          <v-flex xs2 align-self-center class="text-right">
            <div class="pr-0 pr-md-5" v-for="userAnswer in article.userAnswers" :key="userAnswer.id">
              <v-icon large :color="'purple darken-4'" v-text="`mdi-numeric-${userAnswer.correctUserAnswers}-box`"></v-icon> /
              <v-icon large :color="'purple darken-4'" v-text="`mdi-numeric-${userAnswer.totalCorrectAnswers}-box`"></v-icon>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </div>
  </div>
</template>
<script>
import { CoursesGetters } from "@courses/shared/course.store";

export default {
  props: ["courseId"],

  data() {
    return {
      articleId: this.$route.params.articleId ? this.$route.params.articleId : null,
    }
  },
  watch: {
    '$route.params.articleId': function (articleId) {
      this.articleId = articleId ?? null;
    }
  },
  filters: {
    changeDate: (date) => {
      if (!date) {
        return null;
      }
      return date;
    },
  },
  computed: {
    loadingCourse: CoursesGetters.getLoadingCourse,
    course: CoursesGetters.getCourse,

    courseIdLocal: {
      get: function() {
        return this.courseId
      },
      set: function(value) {
        this.$emit('changeCourseId', value)
      }
    }
  },
  methods: {
    goBackToCourses() {
      this.$router.push({ name: 'courses' });
      this.courseIdLocal = null;
    },
    goToArticle(article) {
      this.$router.push({ name: 'article', params: { courseId: this.courseIdLocal, articleId: article.id }});
    },
  }
}
</script>
<style scoped>
.article-count {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #dddddd;
}
.article-count span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  font-weight: bolder;
  font-size: 22px;
}
</style>