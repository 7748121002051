import Coordonatori from "./views/Coordonatori";

const Routes = [
    {
        path: "/coordonatori",
        name: "admin-coordonatori",
        component: Coordonatori,
        meta: {
            title: 'Coordonatori',
        }
    },
]

export default Routes;