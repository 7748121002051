<template>
  <v-container>
    <h2>Dashboard</h2>
    <v-card class="mx-auto mt-2">
      <v-card-text>
        <div>Salut {{ user.first_name }} {{ user.last_name }}</div>
      </v-card-text>
    </v-card>

    <div class="mt-5">
      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-card min-height="150" :loading="settingsLoading">
            <v-card-title class="text-h5">Procentaj promovare</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field color="green" readonly :value="graduatePercentage" label="Procentaj">
                    <v-icon slot="append" color="green">
                      mdi-percent-outline
                    </v-icon>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-btn class="mt-3" :to="{ name: 'admin-settings' }" block color="primary">MODIFICA</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-card style="background-color: #1F7087" dark min-height="150" :loading="coursesLoading">
            <template slot="progress">
              <v-progress-linear color="white" fixed indeterminate></v-progress-linear>
            </template>
            <v-card-title class="text-h5">Cursuri</v-card-title>
            <v-card-subtitle>{{ courses.length }} <template v-if="courses.length === 1">curs</template><template v-else>cursuri</template> </v-card-subtitle>
            <v-card-text>
              <v-btn class="mt-1" :to="{ name: 'admin-courses' }" outlined rounded>
                VEZI
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-card style="background-color: green" dark min-height="150" :loading="certificationsLoading">
            <template slot="progress">
              <v-progress-linear color="white" fixed indeterminate></v-progress-linear>
            </template>
            <v-card-title class="text-h5">Diplome</v-card-title>
            <v-card-subtitle>{{ certifications.length }} <template v-if="certifications.length === 1">diploma</template><template v-else>diplome</template> </v-card-subtitle>
            <v-card-text>
              <v-btn class="mt-1" :to="{ name: 'admin-certifications' }" outlined rounded>
                VEZI
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-card style="background-color: #952175" dark min-height="150" :loading="usersLoading">
            <template slot="progress">
              <v-progress-linear color="white" fixed indeterminate></v-progress-linear>
            </template>
            <v-card-title class="text-h5">Utilizatori</v-card-title>
            <v-card-subtitle>{{ users.length }} <template v-if="users.length === 1">utilizator</template><template v-else>utilizatori</template> </v-card-subtitle>
            <v-card-text>
              <v-btn class="mt-1" :to="{ name: 'admin-users' }" outlined rounded>
                VEZI
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { CoursesActions, CoursesGetters } from "@admin/modules/courses/shared/course.store";
import { UsersActions, UsersGetters } from "@admin/modules/users/shared/users.store";
import { CertificationsActions, CertificationsGetters } from "@admin/modules/certifications/shared/certification.store";
import { SettingsActions, SettingsGetters } from "@admin/modules/settings/shared/settings.store";

export default {
  props: ["user", "isAuthenticated"],
  data() {
    return {
      valid: false,
    }
  },
  computed: {
    courses: CoursesGetters.getCourses,
    coursesLoading: CoursesGetters.getLoadingCourses,

    users: UsersGetters.getUsers,
    usersLoading: UsersGetters.getLoadingUsers,

    certifications: CertificationsGetters.getCertifications,
    certificationsLoading: CertificationsGetters.getLoadingCertifications,

    settings: SettingsGetters.getSettings,
    settingsLoading: SettingsGetters.getLoadingSettings,

    graduatePercentage() {
      const setting = this.settings.find(item => item.key == 'graduate_percentage');
      if (setting) {
        return setting.value
      }
      return '';
    }
  },
  created() {
  },
  async mounted() {
    await SettingsActions.init();
    CoursesActions.init();
    CertificationsActions.init();
    UsersActions.init();
  },
  methods: {

  }
}
</script>