import Certifications from "./views/Certifications";

const Routes = [
    {
        path: "/certifications",
        name: "admin-certifications",
        component: Certifications,
        meta: {
            title: 'Diplome',
        }
    },
]

export default Routes;