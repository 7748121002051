import Vue from "vue";
import { authApi } from "@auth/api";

const initialState = {
    user: null,
    errors: null,
    isAuthenticated: false,
    isAdmin: false,
}

const state = Vue.observable({ ...initialState });

const Getters = {
    isAuthenticated: () => {
        return state.isAuthenticated;
    },
    isAdmin: () => state.isAdmin,
    getErrors: () => state.errors,
    getUser: () => state.user,
}

const Mutations = {
    SET_USER: user => {
        state.user = user;
    },
    SET_AUTHENTIFICATED: authentificated => {
        state.isAuthenticated = authentificated;
        localStorage.setItem("isAuthenticated", authentificated);
    },
    SET_IS_ADMIN: status => {
        state.isAdmin = status;
    },
    SET_ERRORS: errors => {
        state.errors = errors;
    },
    REMOVE_ERRORS: () => {
        state.errors = null;
    },
    RESET_AUTH: () => {
        state.isAuthenticated = false;
        state.user = initialState.user;
        localStorage.removeItem("isAuthenticated");
    }
}

const Actions = {
    init: async () => {
        state.isAuthenticated = localStorage.getItem("isAuthenticated") !== null;
        state.isAdmin = window.location.pathname.substring(1, 6) === 'admin';
    },
    isForbiden: async (accessRole) => {
        if (state.user && state.user.roles && state.isAuthenticated && state.isAdmin) {
            for await (const role of state.user.roles) {
                if (role === accessRole) {
                    return false;
                }
            }
            return true;
        }
        return false;
    },
    loginUser: async credentials => {
        const authResponse = await authApi.login(credentials);
        if (authResponse.data.errors && !authResponse.data.errors.length) {
            Mutations.SET_AUTHENTIFICATED(true);
            await Actions.loadUser();
            Mutations.REMOVE_ERRORS();
        } else {
            Mutations.SET_ERRORS(authResponse.data.errors);
        }
    },
    loadUser: async() => {
        if (Getters.isAuthenticated()) {
            const response = await authApi.loadUser();
            if (response.status === 200) { // ok
                Mutations.SET_USER(response.data.user);
            } else {
                if (response.status === 401) { // unauthorized
                    Actions.logoutUser();
                } else if (response.status === 403) { // forbiden
                    window.location = "/403-forbiden";
                } else {
                    Actions.logoutUser();
                }
            }
        }
    },
    logoutUser: async (reset = true) => {
        if (reset) {
            await authApi.logout();
            Mutations.RESET_AUTH();
        }
        window.location = "/auth/login";
    }
}

export const AuthGetters = Getters;
export const AuthMutations = Mutations;
export const AuthActions = Actions;
