<template>

  <v-container v-if="specializari && gradeProfesionale">

    <h2 class="mb-5">Specializari / Grade profesionale</h2>

    <v-tabs fixed-tabs background-color="primary" dark v-model="tab" class="mb-5">
      <v-tab>
        Specializari
      </v-tab>
      <v-tab>
        Grade profesionale
      </v-tab>
    </v-tabs>



    <v-tabs-items v-model="tab">
      <!-- SPECIALIZARI -->
      <v-tab-item transition="fade-transition">
        <v-card>
          <v-data-table :headers="headersSpecializari" no-data-text="Nicio specializare adaugata" :items="specializariSort" mobile-breakpoint="800" class="elevation-0">

            <template v-slot:item.actions="{ item }">
              <div class="text-truncate">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="mx-0" color="primary" @click="modificaSpecializare(item)">
                      <v-icon>mdi-application-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="mx-0" color="pink" @click="stergeSpecializare(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Sterge</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>

          <v-btn color="green lighten-2" dark class="mt-3 mb-3" @click="dialogSpecializari=true">
            Adauga o specializare
            <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
          </v-btn>


          <v-dialog v-model="dialogSpecializari" max-width="800px">
            <v-card>
              <v-card-title>
                <span v-if="editedItem.id">Modifica specializare</span>
                <span v-else>Adauga specializare</span>
              </v-card-title>

              <v-form ref="formAddSpecializare" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field v-model="editedItem.name" label="Nume specializare" autofocus :rules="[ v => !!v || 'Introduceti numele specializarii']"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>


              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="inchideModalSpecializari">Anuleaza</v-btn>
                <v-btn color="blue darken-1" text @click="saveSpecializare" :loading="loading">Salveaza</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-card>
      </v-tab-item>



      <!-- GRADE PROFESIONALE -->
      <v-tab-item transition="fade-transition">
        <v-card>
          <v-data-table :headers="headersGrade" no-data-text="Niciun grad profesional adaugat" :items="gradeProfesionaleSort" mobile-breakpoint="800" class="elevation-0">

            <template v-slot:item.actions="{ item }">
              <div class="text-truncate">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="mx-0" color="primary" @click="modificaGrad(item)">
                      <v-icon>mdi-application-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="mx-0" color="pink" @click="stergeGrad(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Sterge</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>

          <v-btn color="green lighten-2" dark class="mt-3 mb-3" @click="dialogGrade=true">
            Adauga un grad profesional
            <v-icon small class="ml-3">mdi-plus-circle-outline</v-icon>
          </v-btn>


          <v-dialog v-model="dialogGrade" max-width="800px">
            <v-card>
              <v-card-title>
                <span v-if="editedItem.id">Modifica grad profesional</span>
                <span v-else>Adauga grad profesional</span>
              </v-card-title>

              <v-form ref="formAddGrad" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field v-model="editedItem.name" label="Nume grad profesional" autofocus :rules="[ v => !!v || 'Introduceti numele gradului profesional']"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>


              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="inchideModalGrade">Anuleaza</v-btn>
                <v-btn color="blue darken-1" text @click="saveGrad" :loading="loading">Salveaza</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-card>
      </v-tab-item>
    </v-tabs-items>


  </v-container>


  <v-progress-linear v-else
                     indeterminate
                     color="indigo"
  ></v-progress-linear>

</template>


<script>
import {specializariApi} from "../api";

export default {
  name: "Specializari",
  computed: {
    specializariSort() {
      if (this.specializari) {
        let specializari = this.specializari;
        return specializari.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        })
      }
      return [];
    },
    gradeProfesionaleSort() {
      if (this.gradeProfesionale) {
        let gradeProfesionale = this.gradeProfesionale;
        return gradeProfesionale.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        })
      }
      return [];
    },
  },
  data() {
    return {
      tab: null,

      specializari: null,
      headersSpecializari: [
        { text: 'Specializare', value: 'name' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
      dialogSpecializari: false,

      gradeProfesionale: null,
      headersGrade: [
        { text: 'Specializare', value: 'name' },
        { text: 'Actiuni', value: 'actions', sortable: false },
      ],
      dialogGrade: false,

      editedItem: {},

      valid: false,
      loading: false
    }
  },

  mounted() {
    this.loadItems()
  },

  methods: {
    async loadItems() {
      const specializari = await specializariApi.getAllSpecializari();
      this.specializari = specializari.data;

      const grade = await specializariApi.getAllGrade();
      this.gradeProfesionale = grade.data;
    },

    async saveSpecializare() {
      this.loading = true
      let editedItem = this.editedItem;

      if (!editedItem.id) {
        const response = await specializariApi.addOneSpecializare(editedItem);
        if (response.status) {
          this.specializari.push(response.data);
        }
      } else {
        const response = await specializariApi.updateOneSpecializare(editedItem.id, editedItem);
        if (response.status) {
          this.specializari = this.specializari.map( (item) => (item.id===response.data.id) ? response.data : item )
        }
      }

      this.editedItem = {}
      this.dialogSpecializari = false
      this.loading = false
    },

    async stergeSpecializare(id) {
      const response = await specializariApi.deleteOneSpecializare(id);
      if (response.status) {
        this.specializari = this.specializari.filter( (item) => item.id!==id )
      }
    },

    modificaSpecializare(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogSpecializari = true
    },

    inchideModalSpecializari() {
      this.dialogSpecializari = false
      this.editedItem = {}
    },


    async saveGrad() {
      this.loading = true
      let editedItem = this.editedItem;

      if (!editedItem.id) {
        const response = await specializariApi.addOneGrad(editedItem);
        if (response.status) {
          this.gradeProfesionale.push(response.data);
        }
      } else {
        const response = await specializariApi.updateOneGrad(editedItem.id, editedItem);
        if (response.status) {
          this.gradeProfesionale = this.gradeProfesionale.map( (item) => (item.id===response.data.id) ? response.data : item )
        }
      }

      this.editedItem = {}
      this.dialogGrade = false
      this.loading = false
    },

    async stergeGrad(id) {
      const response = await specializariApi.deleteOneGrad(id);
      if (response.status) {
        this.gradeProfesionale = this.gradeProfesionale.filter( (item) => item.id!==id )
      }
    },

    modificaGrad(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogGrade = true
    },

    inchideModalGrade() {
      this.dialogGrade = false
      this.editedItem = {}
    }
  }
}
</script>