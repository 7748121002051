import axios from "axios";

const getAll = async () => {
    try {
        const response = await axios.get("/api/admin/certifications");
        return response.data
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export default {
    getAll
}