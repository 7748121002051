import axios from "axios";

const getAllCourses = async () => {
    try {
        const response = await axios.get("/api/courses");
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

const getOneCourse = async (courseId) => {
    try {
        const response = await axios.get(`/api/course/${courseId}`);
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

const getOneArticle = async (articleId) => {
    try {
        const response = await axios.get(`/api/article/${articleId}`);
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

const addOneUserQuizAnswers = async (articleId, quizId, dataUserAnswers) => {
    try {
        const response = await axios.post(`/api/user-quiz/${articleId}/${quizId}`, {
            dataUserAnswers
        });
        return response.data
    } catch (error) {
        return { error: error.response.data.message }
    }
}

export default {
    getAllCourses,
    getOneCourse,
    getOneArticle,

    addOneUserQuizAnswers,
}