import Vue from "vue";
import { pagesApi } from "@pages/api";

const initialState = {
    stateStatus: false,

    loadingPages: false,
    loadingPage: false,
    
    pages: null,
    page: null,
}

const state = Vue.observable({ ...initialState });

const Getters = {
    getLoadingPages: () => state.loadingPages,
    getLoadingPage: () => state.loadingPage,

    getPages: () => state.pages,
    getPage: () => state.page,
}

const Mutations = {
    setLoadingPages: (payload) => {
        state.loadingPages = payload;
    },
    setLoadingPage: (payload) => {
        state.loadingPage = payload;
    },

    setPages: (payload) => {
        state.pages = payload;
    },
    setPage: (payload) => {
        state.page = payload;
    },
}

const Actions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await Actions.fetchPages();
        }
        state.stateStatus = true;
    },
    fetchPages: async () => {
        Mutations.setLoadingPages(true);
        const pages = await pagesApi.getAll();
        Mutations.setLoadingPages(false);
        Mutations.setPages(pages.data);
    },
    fetchPage: async (slug) => {
        Mutations.setLoadingPage(true);
        if (state.page && state.page.slug != slug) {
            Mutations.setPage(null);
        }
        const page = await pagesApi.getOne(slug);
        Mutations.setLoadingPage(false);
        Mutations.setPage(page.data);
    }
}

export const PagesGetters = Getters;
export const PagesMutations = Mutations;
export const PagesActions = Actions;
