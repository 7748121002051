<template>
  <v-container v-if="courses">
    <h2 class="mb-5">Cursuri</h2>
    <v-card>
      <v-card-title>
        Lista
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Cauta..." single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :headers="headers" :search="search" :custom-filter="customFilterRomoveDiacritics" no-data-text="Niciun curs adaugat" :items="courses" mobile-breakpoint="800" class="elevation-0">
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mx-0" :to="{name: 'admin-course', params: {id: item.id}}" color="primary">
                  <v-icon>mdi-clipboard-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editeaza curs</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" :to="{name: 'admin-course-articles', params: {id: item.id}}" class="mx-0 ml-5" color="primary">
                  Articole
                </v-btn>
              </template>
              <span>Articole curs</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="downloadCertificates(item)" class="ml-4" href="" color="primary">
                  <v-icon>mdi-certificate-outline</v-icon>
                </v-btn>
              </template>
              <span>Descarca lista absolventi</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="downloadSurveys(item)" class="ml-4" href="" color="primary">
                  <v-icon>mdi-counter</v-icon>
                </v-btn>
              </template>
              <span>Descarca sodaj absolventi</span>
            </v-tooltip>

          </div>
        </template>
        <template v-slot:item.details="{ item }">
          <div class="text-truncate" style="width: 180px">
            {{item.Details}}
          </div>
        </template>
        <template v-slot:item.url="{ item }">
          <div class="text-truncate" style="width: 180px">
            <a :href="item.URL" target="_new">{{item.URL}}</a>
          </div>
        </template>
      </v-data-table>

      <!-- this dialog is used for both create and update -->
      <v-dialog v-model="dialog" max-width="800px">

        <template v-slot:activator="{ on }">
          <div class="d-flex">
            <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
              Adauga curs nou
              <v-icon class="ml-3" small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </template>

        <v-card>
          <v-card-title>
            <span v-if="editedItem.id">Edit {{editedItem.id}}</span>
            <span v-else>Creare curs</span>
          </v-card-title>

          <v-form ref="formAddCurs" v-model="valid" lazy-validation>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="editedItem.name" label="Name" autofocus :rules="[ v => !!v || 'Introduceti numele cursului']"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showEditDialog()">Anuleaza</v-btn>
            <v-btn color="blue darken-1" text @click="saveItem()" :loading="loading">Salveaza</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </v-card>
  </v-container>

  <v-progress-linear v-else
      indeterminate
      color="indigo"
  ></v-progress-linear>
</template>
<script>
import { CoursesActions, CoursesGetters } from "@admin/modules/courses/shared/course.store";

export default {
  props: ["user", "isAuthenticated"],
  data() {
    return {
      APP_SERVER: process.env.VUE_APP_SERVER,

      valid: false,
      search: '',

      dialog: false,
      editedItem: {},

      loading: false,

      headers: [
        { text: 'Id', value: 'id', width: '6%' },
        { text: 'Nume curs', value: 'name' },
        { text: 'Data start', value: 'start_date', width: '12%' },
        { text: 'Data finalizare', value: 'end_date', width: '12%' },
        { text: 'Actiuni', value: 'actions', sortable: false, width: '20%' },
      ],
    }
  },
  async mounted() {
    await CoursesActions.init();
  },
  computed: {
    courses: CoursesGetters.getCourses
  },
  methods: {
    showEditDialog(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = !this.dialog
    },

    async saveItem() {
      this.loading = true
      let editedItem = this.editedItem;

      if(this.$refs.formAddCurs.validate()) {
        if (!editedItem.id) {
          await CoursesActions.addOneCourse(editedItem);
        }
        this.editedItem = {};
        this.dialog = false
      }
      this.loading = false
    },
    async deleteItem(item) {
      await CoursesActions.deteleOneCourse(item.id);
    },
    customFilterRomoveDiacritics(value, search) {
      if (search && value) {
        if (typeof value === 'string') {
          return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.toLowerCase());
        } else {
          return value == search;
        }
      }
    },
    downloadCertificates(course) {
      window.open(this.APP_SERVER + '/api/admin/certifications/' + course.id + '/download', '_blank');
    },

    downloadSurveys(course) {
      window.open(this.APP_SERVER + '/api/admin/surveys/' + course.id + '/download', '_blank');
    }
  }
}
</script>