import Vue from "vue";
import { pagesApi } from "@admin/modules/pages/api";

const initialState = {
    stateStatus: false,

    loadingPages: false,
    pages: [],
    page: {}
}

const state = Vue.observable({ ...initialState });

const Getters = {
    getLoadingPages: () => state.loadingPages,
    getPages: () => state.pages,
    getPage: () => state.page
}

const Mutations = {
    setLoadingPages: (payload) => {
        state.loadingPages = payload;
    },
    setPages: (payload) => {
        state.pages = payload;
    },
    setPage: (payload) => {
        state.page = payload;
    },
    addPage: (payload) => {
        state.pages.push(payload);
    },
    updatePage: (id, payload) => {
        state.pages = state.pages.map(item => {
            if (item.id === id) {
                return payload;
            } else {
                return item;
            }
        });
    },
    deletePage: (id) => {
        state.pages = state.pages.filter(item => item.id !== id);
    },
}

const Actions = {
    init: async (force = false) => {
        if (!state.stateStatus || force === true) {
            await Actions.fetchPages();
        }
        state.stateStatus = true;
    },
    fetchPages: async () => {
        Mutations.setLoadingPages(true);
        const pages = await pagesApi.getAll();
        Mutations.setLoadingPages(false);
        Mutations.setPages(pages.data);
    },
    addPage: async (data) => {
        const response = await pagesApi.addOne(data);
        if (response.status) {
            Mutations.addPage(response.data);
        }
    },
    updatePage: async (id, data) => {
        const response = await pagesApi.updateOne(id, data);
        if (response.status) {
            Mutations.updatePage(id, response.data);
        }
    },
    deletePage: async (id) => {
        const response = await pagesApi.deleteOne(id);
        if (response.status) {
            Mutations.deletePage(id);
        }
    }
}

export const PagesGetters = Getters;
export const PagesMutations = Mutations;
export const PagesActions = Actions;
